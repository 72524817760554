/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import ReactExport from 'react-data-export';
import invoke from "services/invoke.jsx";
import Select from 'react-select';
import { Grid, Tooltip, TextField, MenuItem, Divider, IconButton,
Snackbar, List, ListItem, ListItemText, Avatar, InputAdornment, FormControlLabel, Checkbox, Hidden } from "@material-ui/core";
import orange from '@material-ui/core/colors/orange';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SpecialCard, Table, TableMovile, ItemGrid, Linear, Button, FormDialog, TableSimple } from "components";
import { Search, AddCircle, Cancel, Visibility, AssignmentReturned, Assessment, Info,
Assignment, FormatListBulleted, Computer, ScreenShare, LocalShipping, AssignmentTurnedIn, CheckBox, Description, Print } from "@material-ui/icons";
import dashboardStyle from "assets/jss/material-dashboard-react/dashboardStyle";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import NumberFormat from 'react-number-format';
import { saveAs } from "file-saver";
import { Page, Text, Document, StyleSheet, /*PDFDownloadLink,*/View,  pdf, } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: { backgroundColor: '#FFF', textAlign: 'center', marginTop:'30pt'},
  view: { flexDirection: 'row'},
  headerColspan: { backgroundColor: '#cccccc', border: '1pt solid #000000', fontSize: '12pt', height: '25pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt', fontWeight: 600},
  header: { backgroundColor: '#cccccc', border: '1pt solid #000000', fontSize: '12pt', height: '25pt', width: '45%', marginLeft: '30pt', paddingTop: '5pt'},
  header2: { backgroundColor: '#cccccc', border: '1pt solid #000000', fontSize: '12pt', height: '25pt', width: '45%', paddingTop: '5pt'},
  cellColspan: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '12pt', height: '25pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt',fontWeight: 600, display:'flex'},
  cellColspanItalic: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '12pt', height: '25pt', width: '90%', marginLeft: '30pt', paddingTop: '5pt',fontWeight: 600, display:'flex', fontFamily:'Times-Italic',},
  cell: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '12pt', height: '25pt', width: '45%', marginLeft: '30pt', paddingTop: '5pt'},
  cell2: { backgroundColor: '#fff', border: '1pt solid #555', fontSize: '12pt', height: '25pt', width: '45%', paddingTop: '5pt'},
  text: {fontSize: 12, textAlign: 'justify', border: '1pt solid #555', width: '90%', marginLeft: '30pt', paddingTop: '5pt', paddingLeft:'5pt' }
});

const queryString = require('query-string');
var crypto = require('crypto');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',
  padding: '0px'
});

const colourStyles = (titulo = '"Proveedor"') =>({
  control: styles => ({ ...styles,
    backgroundColor: 'white',
    marginTop: '0px',
    border: 'none',
    borderBottom: '1px solid #777',
    borderRadius: 0,
    borderColor:' #777 !important',
    boxShadow: 'none !important',
    minHeight: '32px'
  }),
  indicatorSeparator: style => ({ ...style,
    display: 'none'
  }),
  container: style => ({ ...style,
    position: 'relative',
    boxSizing: 'border-box',
    paddingTop: '16px',
    marginTop: '16px',
    ':before': {
      content: titulo,
      display: 'block',
      position: 'absolute',
      left: 0,
      top: '1px',
      zIndex: 2,
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '12px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: 1,
    },
  }),
  indicatorsContainer: style => ({ ...style,
    height: '32px'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = '#ddd';
    return {
      ...styles,
      backgroundColor:
        isDisabled ? null
        : isSelected ? '#ddd'
        : isFocused ? '#eee'
        : null,
      color:
        isDisabled ? '#777'
        : isSelected ? 'black'
        : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? color : '#ddd'),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({
    ...styles,
    position: 'relative',
    left: '-10px',
    top: '13px',
    ...dot(data.color)
  }),
  menu: style => ({ ...style,
    animationName: 'selectAnimation',
    animationDuration: '0.267s',
    transition: 'opacity 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 178ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transformOrigin: '128px 0px',
    position:'absolute',
    zIndex: 3
  }),
});


var sesion = invoke.getSesion();
var id_TipoDeEstacion = (sesion && sesion.estacionObj && sesion.estacionObj[0].id_TipoDeEstacion)?sesion.estacionObj[0].id_TipoDeEstacion:1;
var id_EmpresaVenta = (sesion && sesion.estacionObj && sesion.estacionObj[0].id_EmpresaVenta)?sesion.estacionObj[0].id_EmpresaVenta:1;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={6}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class Pedidos extends React.Component {
  //Inicializar estados
  state = {
  //estatus
    open:       false,
    tr:         false,
    tc:         false,
    message:    '',
    classMask:  'maskProgress',
    SearchView: true,
    openDialog: false,
    openDialogCancel: false,
    openDialogFactura: false,
    openDialogSeguimiento: false,
    openDialogConfirmacion: false,
    openDialogVale: false,
    openDialogAcuse: false,
    checkedConfirmacion: 'FALSE',
    nu_CantidadConfirmacion: '',
    urlFactura: '',
    descriptionFactura: '',
    descriptionAcuseCre: 'Descargar acuse cre',
    sn_PuedeCancelar: '',
    sn_RemisionTransito: '',
    id_FormaEntregaCancel: '',
    descriptionCancelar: 'De click en el boton de Aceptar para confirmar la cancelación o de click en cerrar y salir de la opción.',
    descriptionConfirmacion: '',
    principalDesciption: 'Busqueda de pedidos',
    seguimiento: '',
    id_PedidoMD5: '',
    sn_CombustibleImportado: false, // Cliente configurado con pecio pactado y volumen de venta
    sn_TipoCombustibleSurtir: false, // Cliente configurado para pedir producto importado
    nu_CantidadMaxima: 0,

  //tablas
    productos:  [],
    productosAgregar:  [],
    estaciones: [],
    estatus:    [],
    tableData:  [],
    tableDataMovile: [],
    entregaPemex: [],
    entregaPetroil: [],
    clienteRetira: [],

  //combos
    formaEntregas:   [],
    centroEntregas:  [],
    formaCompras:    [],
    cantidades: [],
    proveedores: [],
    operadores: [],
    unidadTrasportes: [],
    direccionEntregas: [],
    sucursales: [],
    turnos: [],
    multiDataSet: [],
    destinos: [],
    firmasVale: [],
    tipoCombustibleSurtir:  [],

  //campos Busqueda
    id_Producto: '',
    id_Estacion: sesion.estacion,
    id_ClienteCE: (sesion.usuario.id_Cliente || 0),
    fl_Pedido: '',
    id_Estatus: '',
    fh_Inicio: '',
    fh_Fin: '',

  //campos Detalle Cliente
    nb_Cliente: '',
    nb_RFC: '',
    nu_EstacionServicio: '',
    nu_ClaveSIIC: '',

  //campos Agregar
    id_FormaEntrega: (id_TipoDeEstacion===3 || id_TipoDeEstacion===4 || id_TipoDeEstacion===5)?2:3,
    id_Proveedor_chosen: { value: 0, label: 'Seleccione' },
    id_CentroEntrega: '',
    id_FormaCompra: '',
    id_ProductoAgregar: '',
    productoAgregar: {},
    cantidadAgregar: {},
    id_Cantidad: '',
    nu_Cantidad: '',
    id_Proveedor: '',
    id_Operador: '',
    id_Unidad: '',
    nb_ProductoAnterior: '',
    id_DireccionEntrega: '',
    id_Sucursal: '',
    fh_Hora: '',
    id_Turno: '',
    fh_EmbarquePedido: '',
    nb_mensajeCancelar: '',
    id_Cancelacion: '',
    fh_view: false,
    fh_EmbarqueDisabled: true,
    sn_Compartido: 'FALSE',
    tonelesUnidadTransporte: [],
    tonelesSeleccionados: [],
    jsonTonelesSeleccionados: null,
    id_TipoCombustibleSurtir: null,
    direccionAgregar: [],

  //campos vale
    nb_SuperIntendente: '',
    nb_AsesorComercial: '',
    nb_Operador: '',
    nb_RFCOperador: '',
    nb_NumeroEquipo: '',
    nu_CapacidadEquipo: '',
    nb_NumeroCertificado: '',
    nb_NumeroPlacas: '',
    nb_ProductoAnteriorVale: '',
    id_Destino: '',
    id_FirmaVale: '',
    id_ProveedorTransportistaVale: '',
    nu_CapacidadVale: '',
    fh_EmbarquePedidoVale: '',

    requeridFormaEntrega:  false,
    requeridCentroEntrega: false,
    requeridFormaCompra: false,
    requeridProductoAgregar: false,
    requeridCantidad: false,
    requeridCantidadNu: false,
    requeridProveedor: false,
    requeridOperador: false,
    requeridUnidad: false,
    requeridProductoAnterior: false,
    requeridDireccionEntrega: false,
    requeridSucursal: false,
    requeridHora: false,
    requeridTurno: false,
    requiredMensajeCancelar: false,
    requiredDestino: false,
    requiredFirmaVale: false,

    //Requeridos de vale de retiro
    required_nb_ProductoAnteriorVale:  false,
    required_nb_NumeroPlacas:  false,
    required_nb_NumeroCertificado:  false,
    required_nu_CapacidadEquipo:  false,
    required_nb_NumeroEquipo:  false,
    required_nb_RFCOperador:  false,
    required_nb_Operador:  false,
    required_nb_AsesorComercial:  false,
    required_nb_SuperIntendente:  false,

    // Acuse Cre
    htmlAcuseCre: '',
    infoAcuse: {},

    // Visualizar Toneles
    openDialogToneles: false,
    descriptionToneles: 'Compartimientos para entrega de producto',
    listaCompartimientos: [],

    // Leyenda facturas con saldo vencido
    de_LeyendaFacturaSaldoVencido: 'Estimado cliente, le recordamos que cuenta con saldo vencido, favor de consultar las facturas pendientes de pago para evitar intereses moratorios y/o suspensión temporal de su crédito.',
    sn_MostrarSaldoVencido: false,
  };

  componentWillMount(){//es parecido a la funcion init
    if(!localStorage.getItem("sesion")){
      localStorage.removeItem("sesion");
      window.location.reload();
    }

    if(this.props.location.search){
      var parsed = queryString.parse(this.props.location.search);
      this.setState({ id_PedidoMD5: '' });

      if(parsed){
        invoke.post('pedidos','findById',{id_Pedido: parsed.pf}).then(res => {
          if(res.status===200 && res.data.data.length){
            this.setState({ id_PedidoMD5: parsed });
            var row = res.data.data[0];
            this.setState({ nu_CantidadConfirmacion: row.nu_Cantidad });
            this.setState({ openDialogConfirmacion: true });
            this.setState({ checkedConfirmacion: 'FALSE' });
            this.setState({ descriptionConfirmacion: (<p style={{ minWidth:'35VW'}}><b>Estacion:</b> {sesion.estacionObj[0].nb_Estacion}<br/><b>Producto:</b> {row.nb_Producto}<br/><b>Litros:</b> {row.nu_Cantidad}<br/><b>Fecha:</b> {row.fh_EmbarquePedido}</p>) });
            invoke.resetTime();
            //abrir modal con toda la info
            //verla info
          }else{
            this.showNotification("tr", 'Codigo no valido')
          }
        });
      }else{
        this.showNotification("tr", 'Codigo no valido')
      }
    }

    this.setState({ nb_Cliente: (sesion.usuario.de_RazonSocial)?sesion.usuario.de_RazonSocial:'' });
    this.setState({ nb_RFC: (sesion.usuario.de_Rfc)?sesion.usuario.de_Rfc:'' });
    this.setState({ nu_EstacionServicio: (sesion.estacionObj[0].nb_Estacion)?sesion.estacionObj[0].nb_Estacion:'' });
    this.setState({ nu_ClaveSIIC: (sesion.estacionObj[0].nu_ClaveSiic)?sesion.estacionObj[0].nu_ClaveSiic:'' });
    this.setState({ id_CentroEntrega: sesion.estacionObj[0].id_Tar });
    this.setState({ sn_TipoCombustibleSurtir: sesion.sn_TipoCombustibleSurtir });

    //Combo de Productos
    invoke.post('productos','combo',{id_Empresa: id_EmpresaVenta, sn_activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var productosVAR = [{value:0,label:'Todos'}];
        var VAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          productosVAR.push({
            value: x.id_Producto,
            label: x.nb_Producto
          });
          VAR.push({
            value: i,
            label: x.nb_Producto,
            obj: x
          });
        }
        this.setState({ productos: productosVAR });
        this.setState({ productosAgregar: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo tipo producto para surtir combustible 
    invoke.post('pedidos','comboTipoCombustible').then(res => {
      if(res.status===200 && res.data.data){
        var tipoCombustibleVAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          tipoCombustibleVAR.push({
            value: x.id_TipoCombustibleSurtir,
            label: x.nb_TipoCombustibleSurtir
          });
        }
        this.setState({ tipoCombustibleSurtir: tipoCombustibleVAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Estaciones
    invoke.post('estacion','combo',{id_Usuario: sesion.usuario.id_Usuario, id_Estacion: null, id_Cliente: sesion.usuario.id_Cliente}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Estacion,
            label: x.nb_Estacion,
            obj: x
          });
        }
        this.setState({ estaciones: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //combo de estatus
    invoke.post('estatus','rango',{id_Inicial: 2101, id_Final: 2110}).then(res => {
      if(res.status===200 && res.data.data){
        var estatusVAR = [{value:0,label:'Todos'}];
        for(var i in res.data.data){
          var x = res.data.data[i];
          estatusVAR.push({
            value: x.id_Estatus,
            label: x.de_Estatus
          });
        }
        this.setState({ estatus: estatusVAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Forma de entrega
    invoke.post('pedidos','listarFormaEntrega',{sn_Activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          if(id_TipoDeEstacion===3 || id_TipoDeEstacion===4 || id_TipoDeEstacion===5){
            if(x.id_FormaDeEntrega === 2 || x.id_FormaDeEntrega === 3){
              VAR.push({
                value: x.id_FormaDeEntrega,
                label: x.nb_FormaDeEntrega
              });
            }
          }else{
            VAR.push({
              value: x.id_FormaDeEntrega,
              label: x.nb_FormaDeEntrega
            });
          }
        }
        this.setState({ formaEntregas: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Centro de entrega
    invoke.post('pedidos','listarCentroEntrega',{sn_Activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Tar,
            label: x.nb_Tar
          });
        }
        this.setState({ centroEntregas: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Forma de Compra
    invoke.post('pedidos','listarFormaCompra',{}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_FormaPago,
            label: x.nb_FormaPago
          });
        }
        this.setState({ formaCompras: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de cantidades
    invoke.post('pedidos','listarCantidades',{sn_Activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        // Filtro de cantidades en caso de ser cliente MGC
        var data = id_TipoDeEstacion === 16 ? res.data.data.filter((item)=>item.id_CantidadPedidoPemex <= 6) : res.data.data;
        for(var i in data){
          var x = data[i];
          VAR.push({
            value: i,
            label: x.nu_Cantidad,
            obj: x
          });
        }
        this.setState({ cantidades: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Proveedores
    invoke.post('pedidos','listarProveedores',{}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Proveedor,
            label: x.nb_Proveedor
          });
        }
        this.setState({ proveedores: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Direccion de Entrega
    invoke.post('pedidos','listarDireccionEntregas',{id_Cliente: sesion.usuario.id_Cliente, id_Estacion:sesion.estacion}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: i,
            label: x.nb_Direccion,
            obj: x
          });
        }
        this.setState({ direccionEntregas: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo de Sucursales
    invoke.post('pedidos','listarSucursales',{id_Empresa:id_EmpresaVenta, id_Plaza: sesion.estacionObj[0].id_Plaza, sn_Activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_Sucursal,
            label: x.nb_Sucursal
          });
        }
        this.setState({ sucursales: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    //Combo Turnos
    invoke.post('pedidos','listarTurnos',{sn_Activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            value: x.id_TurnoPemex,
            label: x.nb_TurnoPemex
          });
        }
        this.setState({ turnos: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });

    var obj = {
      id_Usuario: sesion.usuario.id_Usuario,
      id_Estacion: this.state.id_Estacion?this.state.id_Estacion:null,
      fl_Pedido: this.state.fl_Pedido,
      fh_Inicio: this.state.fh_Inicio,
      fh_Fin: this.state.fh_Fin,
      id_Estatus: this.state.id_EstatusCobranza,
      id_Cliente : sesion.usuario.id_Cliente,
    }
    
    invoke.post('facturas','listadoVencimientos',obj).then(res => {
      if(res.status===200 && res.data.data){
          if(res.data.data.length){
            this.setState({ sn_MostrarSaldoVencido: true });
            console.log(this.state.sn_MostrarSaldoVencido);
          } else{
            this.setState({ sn_MostrarSaldoVencido: false });
          }
      }else{
        this.showNotification("tr", res.data.message)
      }
    });

    var fh_inicio = this.getFirstDayOfMonth();
    var fh_fin = this.getLastDayOfMonth();
    fh_inicio = fh_inicio.toISOString().slice(0,10);
    fh_fin = fh_fin.toISOString().slice(0,10);
    this.setState({ fh_Inicio: fh_inicio });
    this.setState({ fh_Fin: fh_fin });
    setTimeout(function() {
      this.buscar();
      invoke.resetTime();
    }.bind(this), 100);
  }

  printDocument = id => event => {
    const input = document.getElementById('htmlPDF')
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save(id+"download.pdf");
      })
    ;
  }

  showNotification(place,message,time=5000) {
    this.setState({ message: message });
    var val = [];
    val[place] = true;
    this.setState(val);
    setTimeout( function() {
      val[place] = false;
      this.setState(val);
    }.bind(this), time );
  }

  mensajeLog(place,message,time=3000) {
    var val = [];
    val[place] = true;
    this.setState(val);
    this.showNotification("tr",message,5000);
    setTimeout(function() {
      val[place] = false;
      this.setState(val);
    }.bind(this),time);
  };

  buscar() {
    const { classes } = this.props;
    var sesion = invoke.getSesion();
    if(!localStorage.getItem("sesion")){
      window.location.reload();
    }else{
      this.setState({classMask:'maskProgress active'});
      var obj = {
        id_Usuario: sesion.usuario.id_Usuario,
        id_Cliente: (this.state.id_ClienteCE)?this.state.id_ClienteCE:null,
        id_Estacion: (this.state.id_Estacion)?this.state.id_Estacion:null,
        id_Producto: this.state.id_Producto,
        fl_Pedido: this.state.fl_Pedido,
        fh_Inicio: this.state.fh_Inicio,
        fh_Fin: this.state.fh_Fin,
        id_Estatus: this.state.id_Estatus
      }
      /*
      <Tooltip
                      title='Vale de Retiro'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Vale" aria-haspopup="true" onClick={this.openDialogVale(PD)} style={{width:'23px',height:'23px',color:'#1976D2'}} className={classes.buttonTable}>
                        <Description style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
      */
      invoke.post('pedidos','listado',obj)
        .then(res => {
          if(res.status===200 && res.data.data){
            var tableDataVAR = [];
            let tableDataVARMovile = [];
            for(var i in res.data.data){
              var PD = res.data.data[i];
              tableDataVAR.push([
                PD.fl_Pedido,
                PD.fh_EmbarquePedido,
                PD.nb_FormaDeEntrega,
                PD.nb_Estacion,
                PD.nb_FormaPago,
                PD.nb_Producto,
                PD.nu_Cantidad,
                PD.nb_direccion,
                (
                  <Tooltip
                    title={PD.de_Estatus}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                  </Tooltip>
                ),
                (
                  <Tooltip
                    title={PD.de_EstatusSeguimiento}
                    placement={'bottom-start'}
                    enterDelay={300}>
                    <span className={classes.estatus} style={{background: PD.de_ColorSeguimiento}}>{PD.de_EstatusSeguimiento}</span>
                  </Tooltip>
                ),
                (PD.sn_Confirmacion===0 && PD.id_FormaDeEntrega===3)?(
                  <div>
                    <Tooltip
                      title='Cancelar'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Cancel style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Ver Dia'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Ver" aria-haspopup="true" onClick={this.openDates((PD.fh_Pedido)?PD.fh_Pedido.slice(0,10):'')} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Visibility style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Verificar Entrega'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Verificar" aria-haspopup="true" onClick={this.openDialogConfirmacion(PD)} style={{width:'23px',height:'23px',color:'#ff9800'}} className={classes.buttonTable}>
                        <CheckBox style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip>  */}
                  </div>
                ):(PD.id_Estatus===2101)?(
                  <div>
                    <Tooltip
                      title='Cancelar'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Cancel style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Ver Dia'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Ver" aria-haspopup="true" onClick={this.openDates((PD.fh_Pedido)?PD.fh_Pedido.slice(0,10):'')} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Visibility style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                  </div>
                ):(PD.urlFactura)?(<div>
                    <Tooltip
                      title='Descargar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Descargar" disabled={(PD.urlFactura)?false:true} aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} style={{width:'23px',height:'23px',color:'#C62828'}} className={classes.buttonTable}>
                        <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descargar Factura XML'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="DescargarXML" disabled={(PD.urlFacturaXML)?false:true} aria-haspopup="true" style={{width:'23px',height:'23px',color:'#388E3C'}} className={classes.buttonTable}>
                          <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                        </IconButton>
                      </a>
                    </Tooltip>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                  </div>
                ):(<div>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                  </div>)
              ]);
              tableDataVARMovile.push([(<div>
                <table style={{display:'block',width:'100%'}}>
                  <tbody style={{display:'block',width:'100%'}}>
                    <tr style={{display:'block',width:'100%'}}>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Folio Pedido:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.fl_Pedido}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Fecha Embarque:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.fh_EmbarquePedido}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Forma de Entrega:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nb_FormaDeEntrega}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Estación:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nb_Estacion}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Forma de Pago:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nb_FormaPago}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Producto:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nb_Producto}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Cantidad:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>{PD.nu_Cantidad}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div style={{whiteSpace: 'initial',textAlign: 'left'}}><b>Centro de Entrega: </b>{PD.nb_direccion}</div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Estatus Pedido:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>
                          <Tooltip
                            title={PD.de_Estatus}
                            placement={'bottom-start'}
                            enterDelay={300}>
                            <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_Estatus}</span>
                          </Tooltip>
                        </div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Seguimiento:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>
                          <Tooltip
                            title={PD.de_Estatus}
                            placement={'bottom-start'}
                            enterDelay={300}>
                            <span className={classes.estatus} style={{background: PD.de_Color}}>{PD.de_EstatusSeguimiento}</span>
                          </Tooltip>
                        </div>
                      </td>
                      <td style={{ alignItems: 'center',display: 'flex',justifyContent: 'space-between' }}>
                        <div><b>Acciones:</b></div>
                        <div style={{textAlign:'right', whiteSpace: 'initial'}}>
                        {(PD.sn_Confirmacion!==1 && PD.id_FormaDeEntrega===3)?(
                  <div>
                    <Tooltip
                      title='Cancelar'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Cancel style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Ver Dia'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Ver" aria-haspopup="true" onClick={this.openDates((PD.fh_Pedido)?PD.fh_Pedido.slice(0,10):'')} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Visibility style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Verificar Entrega'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Verificar" aria-haspopup="true" onClick={this.openDialogConfirmacion(PD)} style={{width:'23px',height:'23px',color:'#ff9800'}} className={classes.buttonTable}>
                        <CheckBox style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                    </div>
                ):(PD.id_Estatus===2101)?(
                  <div>
                    <Tooltip
                      title='Cancelar'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Cancel style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Ver Dia'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Ver" aria-haspopup="true" onClick={this.openDates((PD.fh_Pedido)?PD.fh_Pedido.slice(0,10):'')} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <Visibility style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                  </div>
                ):(PD.urlFactura)?(<div>
                    <Tooltip
                      title='Descargar Factura PDF'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Descargar" disabled={(PD.urlFactura)?false:true} aria-haspopup="true" onClick={this.downloadPdf( (PD.urlFactura ? PD.urlFactura:''),(PD.urlFacturaDownload ? PD.urlFacturaDownload:''))} style={{width:'23px',height:'23px',color:'#C62828'}} className={classes.buttonTable}>
                        <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descargar Factura XML'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <a href={PD.urlFacturaDownloadXML} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="DescargarXML" disabled={(PD.urlFacturaXML)?false:true} aria-haspopup="true" style={{width:'23px',height:'23px',color:'#388E3C'}} className={classes.buttonTable}>
                          <AssignmentReturned style={{width:'18px',height:'18px'}} className={classes.links} />
                        </IconButton>
                      </a>
                    </Tooltip>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                    </div>
                ):(<div>
                    <Tooltip
                      title='Seguimiento de Pedido'
                      placement={'bottom-start'}
                      enterDelay={300}>
                      <IconButton aria-label="Seguimiento" aria-haspopup="true" onClick={this.openDialogSeguimiento(PD)} style={{width:'23px',height:'23px'}} className={classes.buttonTable}>
                        <FormatListBulleted style={{width:'18px',height:'18px'}} className={classes.links} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title='Descarga de acuse CRE'
                      placement={'bottom-start'}
                      enterDelay={300}>
                        <a style={{visibility: PD.sn_MostrarAcuse, color:'#757575'}} onClick={this.openDialogAcuse(PD)} className={classes.customButton}>CRE</a>
                    </Tooltip> 
                    </div>)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>)]);
            }

            this.setState({ tableData: tableDataVAR });
            this.setState({ tableDataMovile: tableDataVARMovile });

            var DataVAR = [];
            for(i in res.data.data){
              PD = res.data.data[i];
              DataVAR.push([
                {value: (PD.fl_Pedido)?PD.fl_Pedido:'', style: {type:'number', numFmt: "0",alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.fh_EmbarquePedido)?PD.fh_EmbarquePedido:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_FormaDeEntrega)?PD.nb_FormaDeEntrega:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Estacion)?PD.nb_Estacion:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_FormaPago)?PD.nb_FormaPago:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.nb_Producto)?PD.nb_Producto:'', style: {alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' }}},
                {value: (PD.nu_Cantidad)?PD.nu_Cantidad:'', style: {type:'number', numFmt: "#,##0", alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10' }}},
                {value: (PD.nb_direccion)?PD.nb_direccion:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_Estatus)?PD.de_Estatus:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}},
                {value: (PD.de_EstatusSeguimiento)?PD.de_EstatusSeguimiento:'', style: {alignment:{vertical:'center', horizontal:'center'}, font: { sz:'10' }}}
              ]);
            }

            let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);

            let multiDataSet = [
            {
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: 'Reporte de Pedidos', style: { font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              xSteps: 8,
              ySteps: -2,
              columns: [
                {value:''},
              ],
              data: [
                [
                  {value: (localISOTime)+'', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'14', bold: true } }},
                ],
              ]
            },
            {
              columns: [
                {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
              ],
              data: [
                [
                  {value: 'Estacion: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: sesion.estacionObj[0].nb_Estacion, style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Inicio: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Inicio+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ],
                [
                  {value: 'Fecha Fin: ', style: { alignment:{vertical:'center', horizontal:'right'}, font: { sz:'10', bold: true } }},
                  {value: (this.state.fh_Fin+''), style: { alignment:{vertical:'center', horizontal:'left'}, font: { sz:'10' } }},
                  {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''},
                ]
              ]
            },
            {
              xSteps: 0,
              ySteps: 1,
              columns: [
                {value:'Folio Pedido',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Fecha Embarque',style: { wpx: '120', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Forma de Entrega',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Estación',style: { wpx: '130', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Forma de Pago',style: { wpx: '100', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Producto',style: { wpx: '200', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Cantidad',style: { wpx: '80', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Centro de Entrega',style: { wpx: '140', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Estatus Pedido',style: { wpx: '105', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}},
                {value:'Seguimientos Petroil',style: { wpx: '150', alignment:{vertical:'center', horizontal:'center'}, fill: {patternType: "solid", fgColor: {rgb: "FF093D70"}}, font: { sz:'10', color:{rgb: "FFFFFFFF"},bold: true }}}
              ],
              data: DataVAR
            }
          ];
          this.setState({ multiDataSet: multiDataSet });
          } else{
            this.showNotification("tr", res.data.message)
          }

          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 1);
          }.bind(this), 1);
      });
    }
    invoke.resetTime();
  }

  addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
  }


  validar() {
    invoke.resetTime();
    if(this.state.id_FormaEntrega > 0){
      if(this.state.id_FormaEntrega === 1){
        if(this.state.id_CentroEntrega === ''){
          this.mensajeLog("requiredCentroEntrega", 'El centro de entrega es requerido.')
          return false;
        }else if(this.state.id_FormaCompra === ''){
          this.mensajeLog("requiredFormaCompra", 'La Forma de Compra es requerida.')
          return false;
        }else if(!this.state.productoAgregar || !this.state.productoAgregar.id_Producto){
          this.mensajeLog("requiredProductoAgregar", 'El Producto es requerido.')
          return false;
        }else if(!this.state.cantidadAgregar.id_CantidadPedidoPemex){
          this.mensajeLog("requiredCantidad", 'El Cantidad es requerida.')
          return false;
        }else if(this.state.id_Turno === ''){
          this.mensajeLog("requiredTurno", 'El Turno es requerido.')
          return false;
        }
      }else if(this.state.id_FormaEntrega === 2){
        if(this.state.id_FormaCompra === ''){
          this.mensajeLog("requiredFormaCompra", 'La Forma de Compra es requerida.')
          return false;
        }else if(!this.state.direccionAgregar || !this.state.direccionAgregar.id_Domicilio){
          this.mensajeLog("requiredDireccionEntrega", 'La dirección de Entrega es requerida.')
          return false;
        }else if(this.state.id_Sucursal === ''){
          this.mensajeLog("requiredSucursal", 'La Sucursal es requerida.')
          return false;
        }else if(!this.state.productoAgregar || !this.state.productoAgregar.id_Producto){
          this.mensajeLog("requiredProductoAgregar", 'El Producto es requerido.')
          return false;
        }else if(!this.state.nu_Cantidad || this.state.nu_Cantidad==='0'){
          this.mensajeLog("requiredCantidad", 'La Cantidad no puede ser 0 y es requerida.')
          return false;
        }else if( this.state.sn_CombustibleImportado && !this.state.id_TipoCombustibleSurtir){
          this.mensajeLog("requiredTipoCombustibleSurtir", 'El Tipo de Producto es requerido.')
          return false;
        }else if( this.state.sn_CombustibleImportado && this.state.id_TipoCombustibleSurtir === 1 
          && this.state.nu_Cantidad > this.state.nu_CantidadMaxima){
          this.mensajeLog("requiredCantidad", 'La cantidad debe ser menor o igual al volumen restante configurado')
          return false;
        }else if(this.state.fh_Hora === ''){
          this.mensajeLog("requiredHora", 'La Hora es requerida.')
          return false;
        }
      }else if(this.state.id_FormaEntrega === 3){
        if(this.state.id_CentroEntrega === ''){
          this.mensajeLog("requiredCentroEntrega", 'El centro de entrega es requerido.')
          return false;
        }else if(this.state.id_FormaCompra === ''){
          this.mensajeLog("requiredFormaCompra", 'La Fortma de Compra es requerida.')
          return false;
        }else if(!this.state.productoAgregar || !this.state.productoAgregar.id_Producto){
          this.mensajeLog("requiredProductoAgregar", 'El Producto es requerido.')
          return false;
        }else if(!this.state.nu_Cantidad || this.state.nu_Cantidad==='0'){
          this.mensajeLog("requiredCantidad", 'La Cantidad no puede ser 0 y es requerida.')
          return false;
        }else if(this.state.id_Proveedor === ''){
          this.mensajeLog("requiredProveedor", 'El Proveedor Transportista es requerido.')
          return false;
        }else if(this.state.id_Operador === ''){
          this.mensajeLog("requiredOperador", 'El Operador Transportista es requerido.')
          return false;
        }else if(this.state.id_Unidad === ''){
          this.mensajeLog("requiredUnidad", 'La Unidad de Transporte es requerida.')
          return false;
        }
        else if(this.state.sn_Compartido === 'TRUE' && this.state.tonelesSeleccionados.length === 0){
          this.mensajeLog("requiredCompartido", 'Es requerido seleccionar los toneles.')
          return false;
        }else if(this.state.nb_ProductoAnterior === ''){
          this.mensajeLog("requiredProductoAnterior", 'El Producto Anterior es requerido.')
          return false;
        }
      }
    }else{
      this.mensajeLog("requiredFormaEntrega", 'La forma de Entrega es requerida.')
      return false;
    }
    return true;
  }

  handleClickAceptar  = () => {
    const { classes } = this.props;
    var sesion = invoke.getSesion();
    if(!localStorage.getItem("sesion")){
      window.location.reload();
    }else{
      this.setState({classMask: 'maskProgress active'});
      var obj = {
        id_Usuario: sesion.usuario.id_Usuario,
        id_Estacion: sesion.estacion,
        id_Cliente: this.state.id_ClienteCE,
        id_Producto: null,
        fl_Pedido: null,
        fh_Inicio: this.state.fh_EmbarquePedido,
        fh_Fin: this.state.fh_EmbarquePedido,
        id_Estatus: 2101
      }
      invoke.post('pedidos','listado',obj)
        .then(res => {
          if(res.status===200 && res.data.data){
            var entregaPemexVAR = [];
            var entregaPetroilVAR = [];
            var clienteRetiraVAR= [];
            for(var i in res.data.data){
              var x = res.data.data[i];
              if(x.id_FormaDeEntrega===1){
                entregaPemexVAR.push([
                  x.fl_Pedido,
                  x.nb_FormaPago,
                  x.nb_Producto,
                  x.nb_UnidadMedida,
                  x.nb_direccion,
                  x.nb_TurnoPemex,
                  x.fh_EmbarquePedido,
                  x.nu_Cantidad,
                  x.de_Estatus,
                  (x.id_Estatus===2101)?(<IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(x)}><Cancel style={{width:'18px',height:'18px'}} className={classes.links} /></IconButton>):(<div></div>)
                ]);
              }else if(x.id_FormaDeEntrega===2){
                entregaPetroilVAR.push([
                  x.fl_Pedido,
                  x.nb_FormaPago,
                  x.nb_Producto,
                  x.nb_TipoCombustibleSurtir,
                  x.nb_UnidadMedida,
                  x.fh_EmbarquePedido,
                  x.fh_Hora,
                  x.nu_Cantidad,
                  x.de_DomicilioEntrega,
                  x.de_Estatus,
                  (x.id_Estatus===2101)?(<IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(x)}><Cancel style={{width:'18px',height:'18px'}} className={classes.links} /></IconButton>):(<div></div>)
                ]);
              }else if(x.id_FormaDeEntrega===3){
                clienteRetiraVAR.push([
                  x.fl_Pedido,
                  x.nb_FormaPago,
                  x.nb_Producto,
                  x.nb_UnidadMedida,
                  x.nb_direccion,
                  x.fh_EmbarquePedido,
                  x.nu_Cantidad,
                  x.de_Estatus,
                  (x.id_Estatus===2101)? <div> <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogCancel(x)}><Cancel style={{width:'18px',height:'18px'}} className={classes.links} /></IconButton> 
                  <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogToneles(x)} style={{visibility: x.sn_MostrarCompartimiento}} ><LocalShipping style={{width:'18px',height:'18px'}} className={classes.links} /></IconButton> </div>:
                  (<div> <IconButton aria-label="Cancelar" aria-haspopup="true" onClick={this.openDialogToneles(x)} style={{visibility: x.sn_MostrarCompartimiento}}><LocalShipping style={{width:'18px',height:'18px'}} className={classes.links} /></IconButton></div>),
                ]);
              }
            }
            this.setState({ entregaPemex: entregaPemexVAR });
            this.setState({ entregaPetroil: entregaPetroilVAR });
            this.setState({ clienteRetira: clienteRetiraVAR });
          }else{
            this.showNotification("tr", res.data.message)
          }

          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 1);
          }.bind(this), 1);
      });
    }
    invoke.resetTime();
  };

  openDates = fh => event => {
    this.setState({ fh_view: true });
    this.setState({ fh_EmbarquePedido: fh });
    this.setState({ SearchView: false });
    setTimeout(function() {
      this.handleClickAceptar();
    }.bind(this), 100);
    invoke.resetTime();
  };

  handleClick = () => {
    var date = new Date();
    var fh_embarquePedidoVar = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate());
    fh_embarquePedidoVar = fh_embarquePedidoVar.toISOString().slice(0,10);
    this.setState({ fh_EmbarquePedido: fh_embarquePedidoVar });
    this.setState({ fh_view: false });
    this.setState({ SearchView: false });
    this.setState({ principalDesciption: 'Agregar Pedido' });
    this.setState({ entregaPemex: []});
    this.setState({ entregaPetroil: []});
    this.setState({ clienteRetira: []});
    invoke.resetTime();
  };

  handleClickBack = () => {
    this.setState({ SearchView: true });
    this.setState({ principalDesciption: 'Busqueda de pedidos' });
    this.buscar();
    invoke.resetTime();
  };

  handleClickAdd = () => {
    //Validar Fecha antes de agregar
    this.setState({classMask:'maskProgress active'});
    invoke.post('pedidos','validarAgregar',{fh_EmbarquePedido: this.state.fh_EmbarquePedido}).then(res => {
      if(res.status===200 && res.data.data){
        this.setState({
          id_FormaEntrega:  (id_TipoDeEstacion===3 || id_TipoDeEstacion===4 || id_TipoDeEstacion===5)?2:3,
          id_FormaCompra: '',
          id_ProductoAgregar: '',
          id_Cantidad: '',
          nu_Cantidad: '',
          id_Proveedor: '',
          id_Operador: '',
          id_Unidad: '',
          nb_ProductoAnterior: '',
          id_DireccionEntrega: '',
          id_Sucursal: '',
          fh_Hora: '',
          id_Turno: '',
          sn_CombustibleImportado: false,
          productoAgregar: {},
          id_TipoCombustibleSurtir: null,
          direccionAgregar: [],
        });
        this.setState({ openDialog: true });
      }else{
        this.showNotification("tr", res.data.message)
      }
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
      invoke.resetTime();
    });
    invoke.resetTime();
  };

  openDialogSeguimiento = (row) => event => {
    var obj = {
      id_Pedido: row.fl_Pedido,
      id_OrdenSuministro: row.id_OrdenDeSuministro
    }

    this.setState({classMask:'maskProgress active'});
    invoke.post('pedidos','seguimientoPedidos',obj).then(res => {
      if(res.status===200 && res.data.data){
        var x =  res.data.data;
        var seguimientoVAR = (<div></div>)

        if(x[0].de_Comentarios !== ''){
          seguimientoVAR = (<div><label style={{color: 'red'}}>** {x[0].de_Comentarios} **</label></div>)
        }
        else{
          seguimientoVAR = x.map(option =>
            <ListItem>
              <Tooltip  title={option.de_Comentarios} placement={'bottom-start'} enterDelay={(option.de_Comentarios)?100:900*9*9} >
                <Avatar style={{backgroundColor: '#43A047'}}>
                  {(option.id_Seguimiento===1)?<Assignment />:<span></span>}
                  {(option.id_Seguimiento===2)?<Computer />:<span></span>}
                  {(option.id_Seguimiento===3)?<ScreenShare />:<span></span>}
                  {(option.id_Seguimiento===4)?<AssignmentReturned />:<span></span>}
                  {(option.id_Seguimiento===5)?<LocalShipping />:<span></span>}
                  {(option.id_Seguimiento===6)?<AssignmentTurnedIn />:<span></span>}
                  {(option.id_Seguimiento>6)?<Assignment />:<span></span>}
                </Avatar>
              </Tooltip>
              <ListItemText primary={option.nb_estatus} secondary={option.fh_Registro} />
            </ListItem>
          );
          if(x[x.length-1].id_Seguimiento===1){
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><Computer /></Avatar><ListItemText primary="ATENDIDO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><ScreenShare /></Avatar><ListItemText primary="PROGRAMADO PEMEX" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentReturned /></Avatar><ListItemText primary="FACTURADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><LocalShipping /></Avatar><ListItemText primary="EN TRANSITO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
          }else if(x[x.length-1].id_Seguimiento===2){
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><ScreenShare /></Avatar><ListItemText primary="PROGRAMADO PEMEX" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentReturned /></Avatar><ListItemText primary="FACTURADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><LocalShipping /></Avatar><ListItemText primary="EN TRANSITO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
          }else if(x[x.length-1].id_Seguimiento===3){
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentReturned /></Avatar><ListItemText primary="FACTURADO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><LocalShipping /></Avatar><ListItemText primary="EN TRANSITO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
          }else if(x[x.length-1].id_Seguimiento===4){
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><LocalShipping /></Avatar><ListItemText primary="EN TRANSITO" secondary="" /></ListItem></div>)
            seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
          }else if(x[x.length-1].id_Seguimiento===5){
            if(x[4].id_Seguimiento===5){
              seguimientoVAR = (<div>{seguimientoVAR}<ListItem><Avatar><AssignmentTurnedIn /></Avatar><ListItemText primary="ENTREGADO" secondary="" /></ListItem></div>)
            }
          }
        }

        seguimientoVAR = (<div><List>{seguimientoVAR}</List></div>)
        this.setState({ seguimiento: seguimientoVAR });
        this.setState({ openDialogSeguimiento: true });
      }else{
        this.showNotification("tr", res.data.message)
      }

      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
    invoke.resetTime();
  };

  handleClickCloseSeguimiento = () => {
    this.setState({ openDialogSeguimiento: false });
  };

  handleClickDelete = () => {
    if(this.state.nb_mensajeCancelar === ''){
      this.mensajeLog("requiredMensajeCancelar", 'Necesita escribir comentarios de Cancelación.')
      return false;
    }
    if(this.state.sn_PuedeCancelar===0 && (this.state.id_FormaEntregaCancel===1 || this.state.id_FormaEntregaCancel===3)){
      this.showNotification("tr", 'No se puede cancelar el pedido dias despues o durante la fecha de Embarque.',)
      return false;
    }
    if(this.state.sn_RemisionTransito===1){
      this.showNotification("tr", 'No se puede cancelar el pedido porque el pedido ya esta en transito.',)
      return false;
    }
    var sesion = invoke.getSesion();
    this.setState({classMask:'maskProgress active'});
    invoke.post('pedidos','cancelar',{fl_Pedido: this.state.id_Cancelacion, id_usuario: sesion.usuario.id_Usuario, de_Cancelacion: this.state.nb_mensajeCancelar})
      .then(res => {
        if(res.status===200){
          if(res.data.data){
            this.showNotification("tc", res.data.message)
          }else{
            this.showNotification("tr", res.data.message)
          }
          this.buscar();
          this.handleClickAceptar();
        }else{
          this.showNotification("tr", res.data.message)
        }
        setTimeout(function() {
          this.setState({classMask:'maskProgress desactive'});
          setTimeout(function() {
            this.setState({classMask:'maskProgress'});
          }.bind(this), 1);
        }.bind(this), 1);
        this.setState({ openDialogCancel: false });

    });
    invoke.resetTime();
  };

  handleClickConfirmacion = () => {
    this.setState({ openDialogConfirmacion: 'FALSE' });
    if(this.state.checkedConfirmacion==='FALSE'){
      this.showNotification("tr", 'Necesita confirmar el Pedido.')
      return false;
    }
    var sesion = invoke.getSesion();

    this.setState({classMask:'maskProgress active'});
    invoke.post('pedidos','confirmar',{id_Pedido: this.state.id_PedidoMD5, id_usuario: sesion.usuario.id_Usuario})
      .then(res => {
        if(res.status===200){
          if(res.data.data){
            this.showNotification("tc", res.data.message)
          }else{
            this.showNotification("tr", res.data.message)
          }
          this.buscar();
          this.handleClickAceptar();
        }else{
          this.showNotification("tr", res.data.message)
        }
        setTimeout(function() {
          this.setState({classMask:'maskProgress desactive'});
          setTimeout(function() {
              this.setState({classMask:'maskProgress'});
          }.bind(this), 1);
        }.bind(this), 1);
        this.setState({ openDialogConfirmacion: false });
    });
    invoke.resetTime();
  };

  validarVale() {
    invoke.resetTime();

    if(this.state.nu_ClaveSIIC === '')
    {
      this.mensajeLog("required_nu_ClaveSIIC", 'La clave SIIC es requerida. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.id_CentroEntrega === '')
    {
      this.mensajeLog("required_id_CentroEntrega", 'El centro de entrega es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nb_SuperIntendente === '')
    {
      this.mensajeLog("required_nb_SuperIntendente", 'El Super Intendente es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nb_AsesorComercial === '')
    {
      this.mensajeLog("required_nb_AsesorComercial", 'El Asesor Comercial es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nb_Operador === '')
    {
      this.mensajeLog("required_nb_Operador", 'El Operador es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nb_RFCOperador === '')
    {
      this.mensajeLog("required_nb_RFCOperador", 'El RFC del Operador es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nb_NumeroEquipo === '')
    {
      this.mensajeLog("required_nb_NumeroEquipo", 'El Numero de Equipo es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nu_CapacidadEquipo === '')
    {
      this.mensajeLog("required_nu_CapacidadEquipo", 'La Capacidad de Equipo es requerida. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nb_NumeroCertificado === '')
    {
      this.mensajeLog("required_nb_NumeroCertificado", 'El Numero de Certificado es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nb_NumeroPlacas === '')
    {
      this.mensajeLog("required_nb_NumeroPlacas", 'El Numero de Placas es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.nb_ProductoAnteriorVale === '')
    {
      this.mensajeLog("required_nb_ProductoAnteriorVale", 'El Producto Anterior es requerido. Para cualquier aclaración favor de comunicarse al numero de su contacto en Petroil.')
      return false;
    }
    else if(this.state.id_Destino === '')
    {
      this.mensajeLog("requiredDestino", 'El Destino es requerido.')
      return false;
    }
    else if(this.state.id_FirmaVale === '')
    {
      this.mensajeLog("requiredFirmaVale", 'El Autorizador para firma de vale es requerido.')
      return false;
    }

  }

  handleClickVale = () => {
    this.setState({ openDialogVale: 'FALSE' });
    //let sesion = invoke.getSesion();

    let datos = {}
    invoke.resetTime();
    if(this.validarVale()){
      datos = {
        id_Empresa                       : id_EmpresaVenta,
        id_ProgramacionDeRetiro          : '',
        id_ProgramacionDeRetiroDetalle   : '',
        id_ProveedorARetirar             : this.state.id_ProveedorTransportistaVale,
        nb_SuperIntendente               : this.state.nb_SuperIntendente,
        nb_AcesorComercial               : this.state.nb_AsesorComercial,
        cl_SIIC                          : this.state.nu_ClaveSiic,
        id_DestinoPemex                  : this.state.id_Destino,
        nb_DestinoPemex                  : this.state.id_Destino,//falta este
        nb_ProductoTransportoAnt         : this.state.nb_ProductoAnteriorVale,
        id_Autorizador                   : this.state.id_FirmaVale,
        nb_Operador                      : this.state.nb_Operador,
        de_RFC                           : this.state.nb_RFCOperador,
        nb_NumeroEconomico               : this.state.nb_NumeroEquipo,
        nb_Placas                        : this.state.nb_NumeroPlacas,
        nu_Certificado                   : this.state.nb_NumeroCertificado,
        nu_Capacidad                     : this.state.nu_CapacidadVale,
        fh_retiro                        : this.state.fh_EmbarquePedidoVale,
        id_Compartimiento                : '',
        id_EmpresaEmpleado               : 7,
        id_Empleado                      : 1
      }

      this.setState({classMask:'maskProgress active'});
      invoke.post('pedidos','agregarVale',{datos})
        .then(res => {
          if(res.status===200){
            if(res.data.data){
              this.showNotification("tc", res.data.message)
            }else{
              this.showNotification("tr", res.data.message)
            }
            this.buscar();
          }else{
            this.showNotification("tr", res.data.message)
          }
          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 1);
          }.bind(this), 1);
          this.setState({ openDialogConfirmacion: false });
      });

    }

    invoke.resetTime();
  };

  openDialogCancel = (row) => event => {
    var id = row.fl_Pedido;
    var sn_PuedeCancelar = row.sn_PuedeCancelar;
    var sn_RemisionTransito = row.sn_RemisionTransito;
    var id_FormaEntregaCancel = row.id_FormaDeEntrega;
    this.setState({ openDialogCancel: true });
    this.setState({ id_Cancelacion: id });
    this.setState({ id_FormaEntregaCancel: id_FormaEntregaCancel });
    this.setState({ sn_PuedeCancelar: sn_PuedeCancelar });
    this.setState({ sn_RemisionTransito: sn_RemisionTransito });
    this.setState({ nb_mensajeCancelar: '' });
    this.setState({ descriptionCancelar: (<p style={{ minWidth:'35VW'}}><b>Estacion:</b> {sesion.estacionObj[0].nb_Estacion}<br/><b>Producto:</b> {row.nb_Producto}<br/><b>Litros:</b> {row.nu_Cantidad}<br/><b>Fecha:</b> {row.fh_EmbarquePedido}</p>) });
    invoke.resetTime();
  };

  handleClickCloseCancel = () => {
    this.setState({ openDialogCancel: false });
  };

  openDialogToneles = (row) => event => {
    this.setState({classMask:'maskProgress active'});
    invoke.post('pedidos','getCompartimientosByPedido',{id_Pedido: row.fl_Pedido}).then(res => {
        if(res.status===200){
          if(res.data.data){
            const tableDataCompartimientos = [];
            for(var i in res.data.data){
              var x = res.data.data[i];
                tableDataCompartimientos.push([
                  // x.id_Pedido,
                  // x.id_DetalleCompartimiento,
                  // x.id_UnidadDeTransporte,
                  // x.id_Compartimiento,
                  // x.nu_Cantidad,
                  x.Compartimiento,
                  //x.de_Unidad,
                ]);
            };
            if (!tableDataCompartimientos.length) {
              this.showNotification("tr", 'No se encontraron Toneles');
            } else {
              this.setState({ openDialogToneles: true });
            }
            setTimeout(function() {
              this.setState({classMask:'maskProgress desactive'});
              setTimeout(function() {
                  this.setState({classMask:'maskProgress'});
                  this.setState({listaCompartimientos: tableDataCompartimientos})
              }.bind(this), 300);
            }.bind(this), 1);
          }else{
            this.showNotification("tr", res.data.message)
          }
        }else{
          this.showNotification("tr", res.data.message)
        }
        this.setState({ openDialogConfirmacion: false });
    });
    invoke.resetTime();
  };
  handleClickCloseToneles = () => {
    this.setState({ openDialogToneles: false });
  };

  openDialogConfirmacion = (row) => event => {
    this.setState({ id_PedidoMD5: crypto.createHash('md5').update('portalpf'+row.fl_Pedido).digest("hex") });
    this.setState({ nu_CantidadConfirmacion: row.nu_Cantidad });
    this.setState({ openDialogConfirmacion: row.sn_Confirmacion?false:true });
    this.setState({ checkedConfirmacion: row.sn_Confirmacion?'TRUE':'FALSE' });
    this.setState({ descriptionConfirmacion: (<p style={{ minWidth:'35VW'}}><b>Estacion:</b> {sesion.estacionObj[0].nb_Estacion}<br/><b>Producto:</b> {row.nb_Producto}<br/><b>Litros:</b> {row.nu_Cantidad}<br/><b>Fecha:</b> {row.fh_EmbarquePedido}</p>) });
    invoke.resetTime();
  };

  openDialogVale = (row) => event => {
    this.setState({classMask:'maskProgress active'});
    invoke.post('pedidos','valeRead',{id_Pedido: row.fl_Pedido})
      .then(res => {
        if(res.status===200){
          if(res.data.data){

            this.setState({ id_ProveedorTransportistaVale: (res.data.data[0].id_ProveedorTransportistaVale) ? res.data.data[0].id_ProveedorTransportistaVale : '' });
            this.setState({ nu_CapacidadVale: (res.data.data[0].nu_CapacidadVale) ? res.data.data[0].nu_CapacidadVale : '' });
            this.setState({ fh_EmbarquePedidoVale: (res.data.data[0].fh_EmbarquePedidoVale) ? res.data.data[0].fh_EmbarquePedidoVale : '' });

            this.setState({ nb_SuperIntendente: (res.data.data[0].nb_SuperIntendente) ? res.data.data[0].nb_SuperIntendente : '' });
            this.setState({ nb_AsesorComercial: (res.data.data[0].nb_AsesorComercial) ? res.data.data[0].nb_AsesorComercial : '' });
            this.setState({ nb_Operador: (res.data.data[0].nb_Operador) ? res.data.data[0].nb_Operador : '' });
            this.setState({ nb_RFCOperador: (res.data.data[0].de_RFC) ? res.data.data[0].de_RFC : '' });
            this.setState({ nb_NumeroEquipo: (res.data.data[0].nb_Unidad) ? res.data.data[0].nb_Unidad : '' });
            this.setState({ nu_CapacidadEquipo: (res.data.data[0].nu_Capacidad) ? res.data.data[0].nu_Capacidad : '' });
            this.setState({ nb_NumeroCertificado: (res.data.data[0].nu_Certificado) ? res.data.data[0].nu_Certificado : '' });
            this.setState({ nb_NumeroPlacas: (res.data.data[0].nb_Placas) ? res.data.data[0].nb_Placas : '' });
            this.setState({ nb_ProductoAnteriorVale: (res.data.data[0].nb_ProductoTransportoanterior) ? res.data.data[0].nb_ProductoTransportoanterior : '' });
            this.setState({ openDialogVale: true });

            //Combo destinos
            invoke.post('pedidos','listarDestinos',{
              id_Empresa: id_EmpresaVenta,
              id_Cliente: sesion.usuario.id_Cliente,
              id_Estacion: sesion.estacion,
              id_TAR: this.state.id_CentroEntrega
            }).then(res => {
              if(res.status===200 && res.data.data){
                var VAR = [];
                for(var i in res.data.data){
                  var x = res.data.data[i];
                  VAR.push({
                    value: x.id_DestinoPemex,
                    label: x.nb_DestinoPemex
                  });
                }
                this.setState({ destinos: VAR });
              }else{
                this.showNotification("tr", res.message)
              }
            });

            //Combo Firmas vale
            invoke.post('pedidos','listarAutorizadoresFirmasVale',{
              id_Empresa: id_EmpresaVenta,
              sn_Activo: 1
            }).then(res => {
              if(res.status===200 && res.data.data){
                var VAR = [];
                for(var i in res.data.data){
                  var x = res.data.data[i];
                  VAR.push({
                    value: x.id_Autorizador,
                    label: x.nb_Autorizador
                  });
                }
                this.setState({ firmasVale: VAR });
              }else{
                this.showNotification("tr", res.message)
              }
            });

            setTimeout(function() {
              this.setState({classMask:'maskProgress desactive'});
              setTimeout(function() {
                  this.setState({classMask:'maskProgress'});
              }.bind(this), 300);
            }.bind(this), 1);
          }else{
            this.showNotification("tr", res.data.message)
          }

        }else{
          this.showNotification("tr", res.data.message)
        }
        this.setState({ openDialogConfirmacion: false });
    });

    invoke.resetTime();
  };

  handleClickCloseConfirmacion = () => {
    this.setState({ openDialogConfirmacion: false });
  };

  handleClickCloseVale = () => {
    this.setState({ openDialogVale: false });
  };

  downloadPdf = (url,urlDescarga) => event => {
    this.setState({ openDialogFactura: true });
    this.setState({ urlFactura: url });
    this.setState({ urlFacturaDownload: urlDescarga });
  };

  handleClickFactura = () => {
    //window.location.href = this.state.urlFacturaDownload;
    window.open(this.state.urlFacturaDownload, "_blank")
    this.setState({ openDialogFactura: false });
  };

  handleClickFacturaXML = (download) => event => {
    console.log(download);
    //window.location.href = download;
  };

  handleClickCloseFactura = () => {
    this.setState({ openDialogFactura: false });
  };

  handleClickAddDialog = () => {
    invoke.resetTime();
    if(this.validar()){
      var sesion = invoke.getSesion();
      var obj = {}
      if(this.state.id_FormaEntrega===1){
        obj = {
          id_Cliente: sesion.usuario.id_Cliente,
          id_Estacion: sesion.estacion,
          id_Empresa: id_EmpresaVenta,
          id_Sucursal: null,
          id_FormaDeEntrega: this.state.id_FormaEntrega,
          id_TAR: this.state.id_CentroEntrega,
          id_FormaPago: this.state.id_FormaCompra,
          fh_EmbarquePedido: this.state.fh_EmbarquePedido,
          id_Producto: this.state.productoAgregar.id_Producto,
          nb_Producto: this.state.productoAgregar.nb_Producto,
          id_CantidadPedidoPemex: this.state.cantidadAgregar.id_CantidadPedidoPemex,
          nu_Cantidad: this.state.cantidadAgregar.nu_Cantidad,
          id_TurnoPemex: this.state.id_Turno,
          id_ProveedorTransportista: null,
          id_OperadorTransportista: null,
          id_UnidadDeTransporte: null,
          nb_ProductoTransportoanterior: null,
          id_Usuario: sesion.usuario.id_Usuario,
          id_DireccionDeEntrega: null,
          de_DireccionDeEntrega: null,
          sn_PedidoCompartido: 0,
          tonelesSeleccionados: null,
        }
      }else if(this.state.id_FormaEntrega===2){
        obj = {
          id_Cliente: sesion.usuario.id_Cliente,
          id_Estacion: sesion.estacion,
          id_Empresa: id_EmpresaVenta,
          id_Sucursal: this.state.id_Sucursal,
          id_FormaDeEntrega: this.state.id_FormaEntrega,
          id_TAR: null,
          id_FormaPago: this.state.id_FormaCompra,
          fh_EmbarquePedido: (this.state.fh_EmbarquePedido)+((this.state.fh_Hora)?(' '+this.state.fh_Hora):''),
          id_Producto: this.state.productoAgregar.id_Producto,
          nb_Producto: this.state.productoAgregar.nb_Producto,
          id_CantidadPedidoPemex: null,
          nu_Cantidad: this.state.nu_Cantidad,
          id_TurnoPemex: null,
          id_ProveedorTransportista: null,
          id_OperadorTransportista: null,
          id_UnidadDeTransporte: null,
          nb_ProductoTransportoanterior: null,
          id_Usuario: sesion.usuario.id_Usuario,
          id_DireccionDeEntrega: this.state.direccionAgregar.id_Domicilio,
          de_DireccionDeEntrega: this.state.direccionAgregar.nb_Direccion,
          sn_PedidoCompartido: 0,
          tonelesSeleccionados: null,
          id_TipoCombustibleSurtir: this.state.id_TipoCombustibleSurtir,
        }
      }else if(this.state.id_FormaEntrega===3){
        obj = {
          id_Cliente: sesion.usuario.id_Cliente,
          id_Estacion: sesion.estacion,
          id_Empresa: id_EmpresaVenta,
          id_Sucursal: null,
          id_FormaDeEntrega: this.state.id_FormaEntrega,
          id_TAR: this.state.id_CentroEntrega,
          id_FormaPago: this.state.id_FormaCompra,
          fh_EmbarquePedido: this.state.fh_EmbarquePedido,
          id_Producto: this.state.productoAgregar.id_Producto,
          nb_Producto: this.state.productoAgregar.nb_Producto,
          id_CantidadPedidoPemex: null,
          nu_Cantidad: this.state.nu_Cantidad,
          id_TurnoPemex: null,
          id_ProveedorTransportista: this.state.id_Proveedor.value,
          id_OperadorTransportista: this.state.id_Operador.value,
          id_UnidadDeTransporte: this.state.id_Unidad.value,
          nb_ProductoTransportoanterior: this.state.nb_ProductoAnterior,
          id_Usuario: sesion.usuario.id_Usuario,
          id_DireccionDeEntrega: null,
          de_DireccionDeEntrega: null,
          sn_PedidoCompartido: this.state.sn_Compartido === 'TRUE' ? 1 : 0,
          tonelesSeleccionados: this.state.jsonTonelesSeleccionados,
        }
      }
      this.setState({classMask:'maskProgress active'});
      invoke.post('pedidos','agregar',obj)
        .then(res => {
          if (res.status===200 && res.data.data===1) {
            this.showNotification("tc", res.data.message)
            this.handleClickAceptar();
            this.setState({ openDialog: false });
          } else {
            this.showNotification("tr", res.data.message)
          }
          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 1);
          }.bind(this), 1);
      });

    }
    invoke.resetTime();
  };
  handleClickClose = () => {
    invoke.resetTime();
    this.setState({ openDialog: false });
  };
  handleClickSearch = () => {
    invoke.resetTime();
    this.buscar();
  };

  handleChange = name => event => {
    invoke.resetTime();
    // si cambiamos de forma de entrega se setea la cantidad
    if( name === 'id_FormaEntrega' && this.state.nu_Cantidad) {
      this.setState({
        'nu_Cantidad': '',
      });
    }
    if (name === 'id_FormaEntrega' && this.state.sn_Compartido === 'TRUE' && this.state.tonelesSeleccionados.length > 0) {
      setTimeout(function() {
        let suma = 0;
        this.state.tonelesSeleccionados.forEach(item => {
          suma = suma + item.nu_Cantidad;
          this.setState({nu_Cantidad: suma});
        });
      }.bind(this), 100);
    }
    if (name === 'id_FormaEntrega' || name === 'id_Sucursal') { 
      setTimeout(function() {
        this.validarClientesConfVolumenVenta();
      }.bind(this), 100);
    }
    if( name === 'checkedConfirmacion'){
        this.setState({
          [name]: (event.target.value==='FALSE')?'TRUE':'FALSE',
        });
    }else{
      this.setState({
        [name]: (event.target)?event.target.value:event,
      }, () => {
        if( name === 'id_Producto' || name === 'fl_Pedido' || name === 'id_Estatus' || name === 'fh_Inicio' || name === 'fh_Fin'){
          this.buscar();
        }
      });
    }
  };

  handleChangeValidate = (name,validate) => event => {
    invoke.resetTime();
    if(validate.maxLength && (event.target.value).length <= validate.maxLength){
      this.setState({
        [name]: event.target.value,
      });
    }
    else if(validate.date){
        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);
        let VAR = localISOTime;
        let VAL = new Date(event.target.value).toISOString().slice(0, 10);
      if(VAR <= VAL){
        this.setState({
          [name]: event.target.value,
        });
      }
    }
  };

  handleChangeText = (name,text) => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    });
    this.setState({
      [text]: this.state.productosAgregar[event.target.value].obj,
    });
    this.setState({ [text]: this.state.productosAgregar[event.target.value].obj });
    setTimeout(function() {
      this.validarClientesConfVolumenVenta();
    }.bind(this), 100);
  };

  handleChangeCantidad = (name,text) => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    });
    this.setState({ [name]: event.target.value });
    this.setState({
      [text]: this.state.cantidades[event.target.value].obj,
    });
    this.setState({ [text]: this.state.cantidades[event.target.value].obj });
  };

  handleChangeDireccion = (name,text) => event => {
    invoke.resetTime();
    this.setState({
      [name]: event.target.value,
    });
    this.setState({ [name]: event.target.value });
    this.setState({
      [text]: this.state.direccionEntregas[event.target.value].obj,
    });
    this.setState({ [text]: this.state.direccionEntregas[event.target.value].obj });
    setTimeout(function() {
      this.validarClientesConfVolumenVenta();
    }.bind(this), 100);
  };

  handleChangeProveedor = name => event => {
    invoke.resetTime();
    this.setState({
      [name]: (event.target)?event.target.value:event,
    }, () => {

      this.setState({ id_Operador : '' });
      this.setState({ id_Unidad : '' });

      //Combo de Operadores -------------------------------------------------------------------------
      invoke.post('pedidos','listarOperadores',{id_Proveedore: event.value, sn_Activo: 1}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_Operador,
              label: x.nb_Operador
            });
          }
          this.setState({ operadores: VAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
      //Combo de Operadores -------------------------------------------------------------------------

      //Combo de Unidades de Trasporte  -------------------------------------------------------------
      invoke.post('pedidos','listarUnidadTransportes',{id_Proveedore: event.value, sn_Activo: 1}).then(res => {
        if(res.status===200 && res.data.data){
          var VAR = [];
          for(var i in res.data.data){
            var x = res.data.data[i];
            VAR.push({
              value: x.id_UnidadDeTransporte,
              label: x.de_Unidad,
              capacidad: x.nu_Capacidad,
            });
          }
          this.setState({ unidadTrasportes: VAR });
        }else{
          this.showNotification("tr", res.message)
        }
      });
    });

  };

  handleChangeUnidadDeTransporte = name => event => {
    invoke.resetTime();
    this.setState({
      [name]: (event.target)?event.target.value:event,
      'nu_Cantidad': (event.capacidad)?event.capacidad:'',
    });
    this.setState({
      sn_Compartido: 'FALSE',
    });
    this.setState({
      tonelesSeleccionados: [],
    });
    // Obtener los Toneles
    invoke.post('pedidos','listarCompartimientosPorUnidad',{id_UnidadDeTransporte: event.value, sn_Activo: 1}).then(res => {
      if(res.status===200 && res.data.data){
        var VAR = [];
        for(var i in res.data.data){
          var x = res.data.data[i];
          VAR.push({
            id_Compartimiento: x.id_Compartimiento,
            de_Compartimiento: x.de_Compartimiento,
            id_UnidadDeTransporte: x.id_UnidadDeTransporte,
            nu_Cantidad: x.nu_Capacidad,
            sn_Seleccion: 'FALSE',
          });
        }
        this.setState({ tonelesUnidadTransporte: VAR });
      }else{
        this.showNotification("tr", res.message)
      }
    });
  };

  handleChangePedidoCompartido = name => event => {
    invoke.resetTime();
    this.setState({
      [name]: (event.target.value==='FALSE')?'TRUE':'FALSE',
      nu_Cantidad: (this.state.sn_Compartido==='FALSE')? '': this.state.id_Unidad.capacidad,
    });
    const array = this.state.tonelesUnidadTransporte;
    for (let i = 0; i < array.length; i++) {
      array[i].sn_Seleccion = 'FALSE';
    }
    this.setState({ tonelesUnidadTransporte: array });
    this.setState({ tonelesSeleccionados: [] });
    this.setState({jsonTonelesSeleccionados: null});
  };

  handleChangeTonel = (data, index) => event => {
    invoke.resetTime();
    const sn_Checked = (data.sn_Seleccion === 'TRUE' ? 'FALSE' : 'TRUE');
    // La lógica se obtuvo del siguiente enlace: https://stackoverflow.com/questions/42037369/how-to-edit-an-item-in-a-state-array
    const array = this.state.tonelesUnidadTransporte;
    for (let i = 0; i < array.length; i++) {
      if(i===index) {
        array[i].sn_Seleccion = sn_Checked;
      }
    }
    this.setState({ tonelesUnidadTransporte: array });
    
    setTimeout(function() {
      const tonelesSeleccionados = this.state.tonelesUnidadTransporte.filter((item)=> item.sn_Seleccion === 'TRUE');
      this.setState({ tonelesSeleccionados: tonelesSeleccionados });
    }.bind(this), 100);

    setTimeout(function() {
      let suma = 0;
      if (this.state.tonelesSeleccionados.length === 0) {
        this.setState({ nu_Cantidad: '' });
        this.setState({jsonTonelesSeleccionados: null});
        return;
      }

      this.state.tonelesSeleccionados.forEach(item => {
        suma = suma + item.nu_Cantidad;
        this.setState({nu_Cantidad: suma});
      });

      let json = '[';
      this.state.tonelesSeleccionados.forEach((item,index) => {
        json += '{'
        json += '"id_Compartimiento": ' + item.id_Compartimiento + ',';
        json += '"id_UnidadDeTransporte": ' + item.id_UnidadDeTransporte + ',';
        json += '"nu_Capacidad": ' + item.nu_Cantidad;
        json += index < (this.state.tonelesSeleccionados.length - 1) ? '},' : '}'
      });
      json += ']';
      this.setState({jsonTonelesSeleccionados: json});
    }.bind(this), 100);
  };
  // Valida si el cliente esta configurado para venta por volumen
  validarClientesConfVolumenVenta = () => {
    if (this.state.id_FormaEntrega === 2 
        && this.state.id_Sucursal 
        && this.state.productoAgregar) {
          var obj = {
            id_Cliente: this.state.id_ClienteCE,
            id_Empresa: id_EmpresaVenta ,
            id_Sucursal: this.state.id_Sucursal,
            id_Producto: this.state.productoAgregar.id_Producto,
            fh_EmbarquePedido: this.state.fh_EmbarquePedido,
            id_DomicilioEntrega: this.state.direccionAgregar ? this.state.direccionAgregar.id_Domicilio : null,
            id_Estacion: this.state.id_Estacion
          }
          // Validamos que este configurado el cliente para pedir producto importado 
          this.setState({classMask:'maskProgress active'});
          invoke.post('pedidos','validarClientesConfVolumenVenta',obj).then(res => {
            if(res.status===200 && res.data.data){
              var x = res.data.data[0];
              if (x.sn_CombustibleImportado === true) {
                this.setState({
                  sn_CombustibleImportado: true,
                  nu_Cantidad: x.nu_CantidadVolumenRestante,
                  nu_CantidadMaxima: x.nu_CantidadVolumenRestante
                });
              }else {
                this.setState({
                  sn_CombustibleImportado: false,
                  nu_Cantidad: '',
                  nu_CantidadMaxima: 0
                });
              }
            }else{
              this.showNotification("tr", res.data.message)
            }
            setTimeout(function() {
              this.setState({classMask:'maskProgress desactive'});
              setTimeout(function() {
                  this.setState({classMask:'maskProgress'});
              }.bind(this), 100);
            }.bind(this), 1);
            invoke.resetTime();
          });
          invoke.resetTime();
        }
  }
  
  /*PDF ACUSE CRE */
  openDialogAcuse = (row) => event => {
    var obj = {
      id_Pedido: row.fl_Pedido,
      id_PedidoNG: null,
    }

    this.setState({classMask:'maskProgress active'});
    invoke.post('pedidos','getInfoPdfAcuse',obj).then(res => {
      if(res.status===200 && res.data.data){
        var  infoPDF =  res.data.data[0];
        if(!infoPDF){
          this.showNotification("tr", 'No se encontró información para generar el PDF acuse de la CRE.',);
          setTimeout(function() {
            this.setState({classMask:'maskProgress desactive'});
            setTimeout(function() {
                this.setState({classMask:'maskProgress'});
            }.bind(this), 100);
          }.bind(this), 1);
          return false;
        }

        // Estilos
        const headerStyle = { backgroundColor: '#CCCCCC', textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)' };
        const headerStyle50 = { backgroundColor: '#CCCCCC', textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)', width: '50%'};
        const tdStyle ={ textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)' };
        const tdStyle50 ={ textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)',  width: '50%'};
        const tdStyleJustify = { textAlign: 'justify', textJustify: 'inter-word',  wordWrap: 'break-word', border: ' 1px solid rgb(150, 150, 150)' };

        // Generación de PDF
        var html = (<div>
          <table style={{ maxWidth: '900px', tableLayout: 'auto', marginLeft: 'auto', marginRight: 'auto', color: '#000000', border: ' 1px solid rgb(150, 150, 150)', fontSize: '12px'}}>
            <thead>
              <tr>
                <th colSpan={2} style={{ backgroundColor: '#CCCCCC', height: '35px', textAlign: 'center', border: ' 1px solid rgb(150, 150, 150)' }}>
                  <b>COMISION REGULADORA DE ENERGIA</b>
                  </th>
              </tr>
            </thead>
            <tbody>
              <tr><td colSpan={2} style={headerStyle}><b>Folio</b></td></tr>
              <tr><td colSpan={2} style={tdStyle}>{infoPDF.fl_Acuse}</td></tr>
              <tr>
                <td style={headerStyle50}><b>Fecha de Firma</b></td>
                <td style={headerStyle50}><b>Fecha de Recepción</b></td>
              </tr>
              <tr>
                <td style={tdStyle50}>{infoPDF.fh_Firma}</td>
                <td style={tdStyle50}>{infoPDF.fh_Recepcion}</td>
              </tr>
              <tr>
                <td style={headerStyle50}><b>Representante Legal</b></td>
                <td style={headerStyle50}> <b>RFC</b></td>
              </tr>
              <tr>
                <td style={tdStyle50}>{infoPDF.nb_RepresentanteLegal} </td>
                <td style={tdStyle50}>{infoPDF.de_RFC}</td>
              </tr>
              <tr><td colSpan={2} style={headerStyle}><b>Persona Representada</b></td></tr>
              <tr><td colSpan={2} style={tdStyle}>{infoPDF.nb_PersonaRepresentada}</td></tr>
              <tr><td colSpan={2} style={headerStyle}><b>Asunto</b></td></tr>
              <tr><td colSpan={2} style={tdStyleJustify}>{infoPDF.de_Asunto}</td></tr>
              <tr><td colSpan={2} style={headerStyle}><b>Tema o Permiso</b></td></tr>
              <tr><td colSpan={2} style={tdStyle}>{infoPDF.nu_PermisoCRE}</td></tr>
              {(infoPDF.sn_LeyendaCovid)?(
                <tr><td colSpan={2} style={headerStyle}><b>¡Aviso Importante!</b></td></tr>
              ):null}
              {(infoPDF.sn_LeyendaCovid)?(
                <tr>
                  <td colSpan={2} style={tdStyleJustify}>
                    En términos del punto Quinto del <strong>ACUERDO Número A/001/2021</strong> por el que se establece que la suspensión de plazos 
                    y términos legales en la Comisión Reguladora de Energía, como medida de prevención y combate de la propagación del coronavirus COVID-19, 
                    <strong> Publicado en el Diario Oficial de la Federación el 18 de Enero de 2021</strong>, se hace de su conocimiento que los plazos y 
                    terminos legales comenzarán a computarse hasta que la autoridad sanitaria del gobierno federal o autoridades de la Ciudad de México 
                    determinen que no existe un riesgo epidemiológico relacionado con la apertura de las actividades relacionadas con la Administración Pública Federal.
                  </td>
                </tr>
              ):null}
              <tr><td colSpan={2} style={headerStyle}><b>Información Adicional</b></td></tr>
              <tr><td colSpan={2} style={headerStyle}><b>Cliente</b></td></tr>
              <tr><td colSpan={2} style={tdStyle}>{infoPDF.nb_Cliente}</td></tr>
              <tr>
                <td style={headerStyle50}><b>Producto</b></td>
                <td style={headerStyle50}><b>Litros</b></td>
              </tr>
              <tr>
                <td style={tdStyle50}>{infoPDF.nb_ProductoGeneral}</td>
                <td style={tdStyle50}>{infoPDF.nu_Cantidad}</td>
              </tr>
              <tr><td colSpan={2} style={tdStyle}><i>{infoPDF.de_LeyendaFinal}</i></td></tr>
            </tbody>
          </table>
        </div>);
    
        this.setState({htmlAcuseCre: html})
        this.setState({infoAcuse: infoPDF})
        this.setState({ openDialogAcuse: true });
      }else{
        this.showNotification("tr", res.data.message)
      }
    
      setTimeout(function() {
        this.setState({classMask:'maskProgress desactive'});
        setTimeout(function() {
            this.setState({classMask:'maskProgress'});
        }.bind(this), 100);
      }.bind(this), 1);
    });
    invoke.resetTime();
  }

  handleClickCloseAcuse = () => {
    this.setState({ openDialogAcuse: false });
  };

  generatePDFAcuse = async () => {
    const blob = await pdf(
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={styles.headerColspan}>COMISION REGULADORA DE ENERGIA</Text>
          <Text style={styles.headerColspan}>Folio</Text>
          <Text style={styles.cellColspan}>{this.state.infoAcuse.fl_Acuse}</Text>
          <View style={styles.view}>
              <Text style={styles.header}>Fecha de Firma</Text>
              <Text style={styles.header2}>Fecha de Recepción</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.cell}>{this.state.infoAcuse.fh_Firma}</Text>
              <Text style={styles.cell2}>{this.state.infoAcuse.fh_Recepcion}</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.header}>Representante Legal</Text>
              <Text style={styles.header2}>RFC</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.cell}>{this.state.infoAcuse.nb_RepresentanteLegal}</Text>
              <Text style={styles.cell2}>{this.state.infoAcuse.de_RFC}</Text>
          </View>
          <Text style={styles.headerColspan}>Persona Representada</Text>
          <Text style={styles.cellColspan}>{this.state.infoAcuse.nb_PersonaRepresentada}</Text>
          <Text style={styles.headerColspan}>Asunto</Text>
          <Text style={styles.text}>
            {this.state.infoAcuse.de_Asunto}
          </Text>
          <Text style={styles.headerColspan}>Tema o Permiso</Text>
          <Text style={styles.cellColspan}>{this.state.infoAcuse.nu_PermisoCRE}</Text>
          {(this.state.infoAcuse.sn_LeyendaCovid)?(
              <Text style={styles.headerColspan}>¡Aviso Importante!</Text>
            ):<Text></Text>
          }
          {(this.state.infoAcuse.sn_LeyendaCovid)?(
            <Text style={styles.text}>
              En términos del punto Quinto del ACUERDO Número A/001/2021 por el que se establece que la suspensión de plazos 
              y términos legales en la Comisión Reguladora de Energía, como medida de prevención y combate de la propagación del coronavirus COVID-19, 
              Publicado en el Diario Oficial de la Federación el 18 de Enero de 2021, se hace de su conocimiento que los plazos y 
              terminos legales comenzarán a computarse hasta que la autoridad sanitaria del gobierno federal o autoridades de la Ciudad de México 
              determinen que no existe un riesgo epidemiológico relacionado con la apertura de las actividades relacionadas con la Administración Pública Federal.
            </Text>
            ):<Text></Text>
          }
          <Text style={styles.headerColspan}>Información Adicional</Text>
          <Text style={styles.headerColspan}>Cliente</Text>
          <Text style={styles.cellColspan}>{this.state.infoAcuse.nb_Cliente}</Text>
          <View style={styles.view}>
              <Text style={styles.header}>Producto</Text>
              <Text style={styles.header2}>Litros</Text>
          </View>
          <View style={styles.view}>
              <Text style={styles.cell}>{this.state.infoAcuse.nb_ProductoGeneral}</Text>
              <Text style={styles.cell2}>{this.state.infoAcuse.nu_Cantidad}</Text>
          </View>
          <Text style={styles.cellColspanItalic}>{this.state.infoAcuse.de_LeyendaFinal}</Text>
        </Page>
      </Document>
    ).toBlob()
    saveAs(blob, "AcuseCRE  - " + this.state.infoAcuse.fl_Acuse);
  };

  noOfDaysInMonth(){
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  getFirstDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  getLastDayOfMonth(){
    var date = new Date();
    return new Date(date.getFullYear(),
      date.getMonth(),
      this.noOfDaysInMonth(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  onError = () => e => {
    console.log(e);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <ItemGrid xs={12} sm={12} md={12}>
          <SpecialCard
            icon={Assignment}
            iconColor="orange"
            cardTitle="Pedidos de Combustible"
            cardSubtitle={this.state.principalDesciption}
            content={(this.state.SearchView)?
              (<div>
                <div className={classes.backForm}>
                <MuiThemeProvider theme={theme}>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="id_Producto"
                        select
                        label="Producto"
                        className={classes.textField}
                        value={this.state.id_Producto}
                        onChange={this.handleChange('id_Producto')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.productos.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="id_Estacion"
                        select
                        label="Estación"
                        className={classes.textField}
                        value={this.state.id_Estacion}
                        onChange={this.handleChange('id_Estacion')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.estaciones.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="name"
                        label="Folio de Pedido"
                        className={classes.textField}
                        value={this.state.fl_Pedido}
                        onChange={this.handleChangeValidate('fl_Pedido',{'maxLength':10})}
                        margin="normal"
                        type="number"
                        fullWidth
                      />
                    </ItemGrid>
                  </Grid>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="fh_Inicio"
                        label="Fecha Embarque Inicio"
                        type="date"
                        className={classes.textField}
                        value={this.state.fh_Inicio}
                        onChange={this.handleChange('fh_Inicio')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="fh_Fin"
                        label="Fin"
                        type="date"
                        className={classes.textField}
                        value={this.state.fh_Fin}
                        onChange={this.handleChange('fh_Fin')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={3}>
                      <TextField
                        id="id_Estatus"
                        select
                        label="Estatus Pedido"
                        className={classes.textField}
                        value={this.state.id_Estatus}
                        onChange={this.handleChange('id_Estatus')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.estatus.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                    <ItemGrid xs={1} sm={1} md={1}>
                      <Tooltip  title={'Buscar'} placement={'bottom-start'} enterDelay={100} >
                        <IconButton
                            color="primary"
                            aria-label="Agregar"
                            aria-haspopup="true"
                            onClick={this.handleClickSearch}>
                          <Search className={classes.links} />
                        </IconButton>
                      </Tooltip>
                    </ItemGrid>

                  </Grid>
                  {(this.state.sn_MostrarSaldoVencido)?(
                    <Grid container>
                        <div style={{border:'1px solid', borderRadius: '5px', padding:'5px', color:'#d32f2f', backgroundColor:'#FFCDD2'}}>
                           <span style={{color:'#d32f2f',fontSize:'14px'}}><Info style={{color:'#d32f2f',fontSize:'14px'}}/> {this.state.de_LeyendaFacturaSaldoVencido}</span>  
                        </div>
                    </Grid>
                    ):null
                  }
                  </MuiThemeProvider>
                </div>
                <Grid container>
                <Hidden smDown>
                  <Table xs={12} sm={12} md={12}
                    tableHead={[
                      { id: 'fl_Pedido', numeric: true, disablePadding: false, label: 'Folio Pedido' },
                      { id: 'fh_EmbarquePedido', numeric: false, disablePadding: false, label: 'Fecha Embarque' },
                      { id: 'nb_FormaDeEntrega', numeric: false, disablePadding: false, label: 'Forma de Entrega' },
                      { id: 'nb_Estacion', numeric: false, disablePadding: false, label: 'Estación' },
                      { id: 'nb_FormaPago', numeric: false, disablePadding: false, label: 'Forma de Pago' },
                      { id: 'nb_Producto', numeric: false, disablePadding: false, label: 'Producto' },
                      { id: 'nu_Cantidad', numeric: true, disablePadding: false, label: 'Cantidad' },
                      { id: 'nb_direccion', numeric: false, disablePadding: false, label: 'Centro de Entrega' },
                      { id: 'de_Estatus', numeric: false, disablePadding: false, label: 'Estatus Pedido' },
                      { id: 'de_EstatusSeguimiento', numeric: false, disablePadding: false, label: 'Seguimientos Petroil' },
                      { id: 'Acciones', numeric: false, disablePadding: false, label: 'Acciones' }
                    ]}
                    tableData={this.state.tableData}
                  />
                </Hidden>
                <Hidden mdUp>
                  <TableMovile xs={12} sm={12} md={12}
                    tableHead={[
                      { id: 'listado', numeric: true, disablePadding: false, label: 'Listado de Pedidos' },
                    ]}
                    tableData={this.state.tableDataMovile}
                  />
                </Hidden>
              </Grid>
              </div>)
              :(
                <MuiThemeProvider theme={theme}>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={6}>
                      <TextField
                        id="nb_Cliente"
                        label="Cliente"
                        className={classes.textField}
                        value={this.state.nb_Cliente}
                        onChange={this.handleChange('nb_Cliente')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#ff9800'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: 'rgb(13, 67, 119)'}
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={4}>
                      <TextField
                        id="nb_RFC"
                        label="RFC"
                        className={classes.textField}
                        value={this.state.nb_RFC}
                        onChange={this.handleChange('nb_RFC')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#ff9800'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: 'rgb(13, 67, 119)'}
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={4}>
                      <TextField
                        id="nu_EstacionServicio"
                        label="Número de Estación de Servicio"
                        className={classes.textField}
                        value={this.state.nu_EstacionServicio}
                        onChange={this.handleChange('nu_EstacionServicio')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#ff9800'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: 'rgb(13, 67, 119)'}
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={4}>
                      <TextField
                        id="nu_ClaveSIIC"
                        label="Clave SIIC"
                        className={classes.textField}
                        value={this.state.nu_ClaveSIIC}
                        onChange={this.handleChange('nu_ClaveSIIC')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#ff9800'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: 'rgb(13, 67, 119)'}
                        }}
                      />
                    </ItemGrid>
                  </Grid>
                  <br/>
                  <Divider />
                  <br/>
                  <Grid container>
                    <ItemGrid xs={8} sm={8} md={4}>
                      <TextField
                        id="fh_EmbarquePedido"
                        label="Fecha de Embarque Pedido"
                        type="date"
                        disabled={this.state.fh_view}
                        className={classes.textField}
                        value={this.state.fh_EmbarquePedido}
                        onChange={this.handleChangeValidate('fh_EmbarquePedido',{'date':true,'min': new Date()})}
                        InputLabelProps={{
                          shrink: true
                        }}
                        margin="dense"
                        fullWidth
                      />
                    </ItemGrid>
                    <ItemGrid xs={4} sm={4} md={4}>
                      <Button color="info" onClick={this.handleClickAceptar}>Aceptar</Button>
                    </ItemGrid>
                    <ItemGrid xs={1} sm={1} md={3}></ItemGrid>
                    <ItemGrid xs={1} sm={1} md={1}>
                      <br/>
                      <IconButton
                          aria-label="Agregar"
                          aria-haspopup="true"
                          disabled={this.state.fh_view}
                          onClick={this.handleClickAdd}>
                        <AddCircle className={classes.links} />
                      </IconButton>
                    </ItemGrid>
                  </Grid>
                  {(this.state.entregaPemex.length > 0)?(<Grid container>
                    <TableSimple
                      title="Entrega por Pemex"
                      tableHead={[
                        { numeric: false, label: 'Folio Pedido' },
                        { numeric: false, label: 'Forma de Pago' },
                        { numeric: false, label: 'Producto' },
                        { numeric: false, label: 'Unidad Medida' },
                        { numeric: false, label: 'Centro de Entrega' },
                        { numeric: false, label: 'Turno' },
                        { numeric: false, label: 'Fecha Programada' },
                        { numeric: true , label: 'Cantidad' },
                        { numeric: false, label: 'Estatus' },
                        { numeric: false, label: 'Acciones' }
                      ]}
                      tableData={this.state.entregaPemex}
                    />
                  </Grid>):null}
                  {(this.state.entregaPetroil.length > 0)?(<Grid container>
                    <TableSimple
                      title="Entrega por Petroil"
                      tableHead={[
                        { numeric: false, label: 'Folio Pedido' },
                        { numeric: false, label: 'Forma de Pago' },
                        { numeric: false, label: 'Producto' },
                        { numeric: false, label: 'Tipo Producto' },
                        { numeric: false, label: 'Unidad Medida' },
                        { numeric: false, label: 'Fecha Programada' },
                        { numeric: false, label: 'Hora' },
                        { numeric: false, label: 'Cantidad' },
                        { numeric: true , label: 'Direccion de Entraga' },
                        { numeric: false, label: 'Estatus' },
                        { numeric: false, label: 'Acciones' }
                      ]}
                      tableData={this.state.entregaPetroil}
                    />
                  </Grid>):null}
                  {(this.state.clienteRetira.length > 0)?(<Grid container>
                    <TableSimple
                      title="Cliente Retira Producto"
                      tableHead={[
                        { numeric: false, label: 'Folio Pedido' },
                        { numeric: false, label: 'Forma de Pago' },
                        { numeric: false, label: 'Producto' },
                        { numeric: false, label: 'Unidad Medida' },
                        { numeric: false, label: 'Centro de Entrega' },
                        { numeric: false, label: 'Fecha Programada' },
                        { numeric: true , label: 'Cantidad' },
                        { numeric: false, label: 'Estatus' },
                        { numeric: false, label: 'Acciones' }
                      ]}
                      tableData={this.state.clienteRetira}
                    />
                  </Grid>):null}
                </MuiThemeProvider>
              )
            }
            add = {this.handleClick}
            excel = {<div><ExcelFile element={
              <div style={{top:'0',right:'50px',position:'absolute'}}>
                <Tooltip
                  title={'Descargar Excel'}
                  placement={'bottom-start'}
                  enterDelay={100}
                >
                  <IconButton
                      aria-label="Excel"
                      aria-haspopup="true"
                      className={classes.buttonLink}>
                    <Assessment className={classes.links} />
                  </IconButton>
                </Tooltip>
              </div>
            }>
              <ExcelSheet dataSet={this.state.multiDataSet} name="Pedidos"/>
            </ExcelFile></div>}
            back = {this.handleClickBack}
            edit = {this.state.SearchView}
          />
          <div className={this.state.classMask}>
            <Linear  />
          </div>
          <FormDialog
            title="Agregar Solicitud"
            description= {(<div><Grid container>
              <ItemGrid xs={12} sm={12} md={8}>
                <label style={{color:'#0d4377',fontSize:'1em'}}>Una vez creada la solicitud solo se podra cancelar un dia despues de generarla.</label>
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={4}>
                <TextField
                  id="fh_EmbarquePedido"
                  label="Fecha de Embarque Pedido"
                  type="date"
                  disabled={this.state.fh_EmbarqueDisabled}
                  className={classes.textField}
                  value={this.state.fh_EmbarquePedido}
                  onChange={this.handleChangeValidate('fh_EmbarquePedido',{'date':true,'min': new Date()})}
                  InputLabelProps={{
                    style: {color: '#ff9800'},
                    shrink: true,
                  }}
                  InputProps={{
                    style: {color: 'rgb(13, 67, 119)'}
                  }}
                  margin="dense"
                  fullWidth
                />
              </ItemGrid></Grid></div>)}
            content= {
              <div>
                <Grid container>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_FormaEntrega"
                      select
                      error={this.state.requiredFormaEntrega}
                      label="Forma de Entrega"
                      className={classes.textField}
                      value={this.state.id_FormaEntrega}
                      onChange={this.handleChange('id_FormaEntrega')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        },
                        value: this.state.id_FormaEntrega
                      }}
                      margin="normal"
                      fullWidth
                      autoFocus
                    >
                      {this.state.formaEntregas.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                  {(this.state.id_FormaEntrega===1 || this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={6}>
                      <TextField
                        id="id_CentroEntrega"
                        select
                        error={this.state.requiredCentroEntrega}
                        label="Centro de Entrega"
                        className={classes.textField}
                        value={this.state.id_CentroEntrega}
                        onChange={this.handleChange('id_CentroEntrega')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                          value: this.state.id_CentroEntrega
                        }}
                        disabled
                        margin="normal"
                        fullWidth
                      >
                        {this.state.centroEntregas.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                  ):null}
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_FormaCompra"
                      select
                      error={this.state.requiredFormaCompra}
                      label="Forma de Compra"
                      className={classes.textField}
                      value={this.state.id_FormaCompra}
                      onChange={this.handleChange('id_FormaCompra')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.formaCompras.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                  {(this.state.id_FormaEntrega===2)?(
                    <ItemGrid xs={12} sm={12} md={12}>
                      <TextField
                        id="id_DireccionEntrega"
                        select
                        error={this.state.requiredDireccionEntrega}
                        label="Direccion de Entrega"
                        className={classes.textField}
                        value={this.state.id_DireccionEntrega}
                        onChange={this.handleChangeDireccion('id_DireccionEntrega','direccionAgregar')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.direccionEntregas.map( (option, index) => (
                          <MenuItem key={option.value} value={index}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===2)?(
                    <ItemGrid xs={12} sm={12} md={6}>
                      <TextField
                        id="id_Sucursal"
                        select
                        error={this.state.requiredSucursal}
                        label="Sucursal"
                        className={classes.textField}
                        value={this.state.id_Sucursal}
                        onChange={this.handleChange('id_Sucursal')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.sucursales.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                  ):null}
                    <ItemGrid xs={12} sm={12} md={6}>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={6}>
                      <TextField
                        id="id_ProductoAgregar"
                        select
                        error={this.state.requiredProductoAgregar}
                        label="Producto"
                        className={classes.textField}
                        value={this.state.id_ProductoAgregar}
                        onChange={this.handleChangeText('id_ProductoAgregar','productoAgregar')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.productosAgregar.map((option,index) => (
                          <MenuItem key={option.value} value={index}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                    {(this.state.id_FormaEntrega===1)?(
                      <ItemGrid xs={12} sm={12} md={6}>
                        <TextField
                          id="id_Cantidad"
                          select
                          error={this.state.requiredCantidad}
                          label="Cantidad"
                          className={classes.textField}
                          value={this.state.id_Cantidad}
                          onChange={this.handleChangeCantidad('id_Cantidad','cantidadAgregar')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          fullWidth
                        >
                          {this.state.cantidades.map((option,index) => (
                            <MenuItem key={option.value} value={index}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ItemGrid>
                    ):null}
                    {(this.state.id_FormaEntrega > 1)?(
                      <ItemGrid xs={12} sm={12} md={6}>
                        <TextField
                          className={classes.formControl}
                          error={this.state.requiredCantidad}
                          label="Cantidad"
                          value={this.state.nu_Cantidad}
                          onChange={this.handleChange('nu_Cantidad')}
                          id="Cantidad"
                          margin="normal"
                          fullWidth
                          disabled={this.state.id_FormaEntrega===3}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            style: {textAlign:'right'}
                          }}
                        />
                      </ItemGrid>
                    ):null}
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={12}>
                      <label style={{color:'#ff0000',fontSize:'1em'}}>*En esta forma de entrega la cantidad del pedido será la capacidad de la unidad de transporte seleccionada.</label>
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={6}>
                      <Select
                        value={this.state.id_Proveedor}
                        onChange={this.handleChangeProveedor('id_Proveedor')}
                        options={this.state.proveedores}
                        styles={colourStyles('"Proveedor Transportista"')}
                        placeholder={"Seleccionar"}
                      />
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={6}>
                      <Select
                        value={this.state.id_Operador}
                        onChange={this.handleChange('id_Operador')}
                        options={this.state.operadores}
                        styles={colourStyles('"Operador Transportista"')}
                        placeholder={"Seleccionar"}
                      />
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={12}>
                      <Select
                        value={this.state.id_Unidad}
                        onChange={this.handleChangeUnidadDeTransporte('id_Unidad')}
                        options={this.state.unidadTrasportes}
                        styles={colourStyles('"Unidad de Transporte"')}
                        placeholder={"Seleccionar"}
                      />
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===3 && this.state.tonelesUnidadTransporte.length > 1)?(
                    <ItemGrid xs={3} sm={3} md={3}>
                      <Checkbox
                        checked={(this.state.sn_Compartido==='TRUE')?true:false}
                        onChange={this.handleChangePedidoCompartido('sn_Compartido')}
                        value={this.state.sn_Compartido}
                        error={this.state.requiredCompartido}
                        style={{color:'#0d4377'}}
                      />
                      <label style={{color:'#000000'}}>¿Es un Pedido Compartido?</label>
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===3 && this.state.sn_Compartido==='TRUE')?(
                    this.state.tonelesUnidadTransporte.map((option,index) => (
                      <ItemGrid xs={3} sm={3} md={3}>
                        <Checkbox
                          checked={(option.sn_Seleccion==='TRUE')?true:false}
                          onChange={this.handleChangeTonel(option, index)}
                          value={option.sn_Seleccion}
                          style={{color:'#0d4377'}}
                        />
                        <label style={{color:'#000000'}}>{option.de_Compartimiento}</label>
                      </ItemGrid>
                    ))
                  ):null}
                  {(this.state.id_FormaEntrega===3)?(
                    <ItemGrid xs={12} sm={12} md={12}>
                      <TextField
                        id="nb_ProductoAnterior"
                        error={this.state.requiredProductoAnterior}
                        label="Producto que transporto en el viaje anterior"
                        className={classes.textField}
                        value={this.state.nb_ProductoAnterior}
                        onChange={this.handleChangeValidate('nb_ProductoAnterior',{maxLength:50})}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===1)?(
                    <ItemGrid xs={12} sm={12} md={6}>
                      <TextField
                        id="id_Turno"
                        select
                        error={this.state.requiredTurno}
                        label="Turno"
                        className={classes.textField}
                        value={this.state.id_Turno}
                        onChange={this.handleChange('id_Turno')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        fullWidth
                      >
                        {this.state.turnos.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega === 2 && this.state.sn_TipoCombustibleSurtir === true 
                    && this.state.sn_CombustibleImportado === true )?( 
                    <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_TipoCombustibleSurtir"
                      select
                      error={this.state.requiredTipoCombustibleSurtir}
                      label="Tipo de Producto"
                      className={classes.textField}
                      value={this.state.id_TipoCombustibleSurtir}
                      onChange={this.handleChange('id_TipoCombustibleSurtir')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.tipoCombustibleSurtir.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    </ItemGrid>
                  ):null}
                  {(this.state.id_FormaEntrega===2)?(
                    <ItemGrid xs={12} sm={12} md={6}>
                      <TextField
                        id="fh_Hora"
                        label="Hora Entrega Requerida"
                        error={this.state.requiredHora}
                        type="time"
                        value={this.state.fh_Hora}
                        onChange={this.handleChange('fh_Hora')}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        margin="normal"
                        fullWidth
                      />
                    </ItemGrid>
                  ):null}
                </Grid>
              </div>
            }
            btn= 'Agregar'
            action= {this.handleClickAddDialog}
            close={this.handleClickClose}
            open= {this.state.openDialog}
          />
          <FormDialog
            title="Cancelar Solicitud"
            description= {this.state.descriptionCancelar}
            content= {
              <div>
                <TextField
                  id="nb_mensajeCancelar"
                  label="Comentarios"
                  error={this.state.requiredMensajeCancelar}
                  multiline
                  rowsMax="3"
                  value={this.state.nb_mensajeCancelar}
                  onChange={this.handleChange('nb_mensajeCancelar')}
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                  autoFocus
                />
              </div>
            }
            btn= 'Aceptar'
            action= {this.handleClickDelete}
            close={this.handleClickCloseCancel}
            open= {this.state.openDialogCancel}
          />
          <FormDialog
            title="Compartimientos Unidad Transporte"
            description= {this.state.descriptionToneles}
            content= {
              <div>
                {(this.state.listaCompartimientos.length > 0)?(<Grid container>
                    <TableSimple
                    tableHead={[
                      { numeric: false, label: 'Compartimiento' },
                    ]}
                    tableData={this.state.listaCompartimientos}
                  />
                  </Grid>)
                :null}
              </div>
            }
            close={this.handleClickCloseToneles}
            open= {this.state.openDialogToneles}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><AssignmentTurnedIn /><span style={{paddingLeft:'15px'}}>Confirmación de Pedido</span></InputAdornment></div>)}
            description= {this.state.descriptionConfirmacion}
            content= {
              <div style={{maxWidth:'550px'}}>
                <FormControlLabel
                  control={
                    <Checkbox

                      checked={(this.state.checkedConfirmacion==='TRUE')?true:false}
                      onChange={this.handleChange('checkedConfirmacion')}
                      value={this.state.checkedConfirmacion}
                      style={{color:'#ff9800'}}
                    />
                  }
                  label={(<p>Recibí a mi entera satisfacción <b> {this.state.nu_CantidadConfirmacion} litros</b>, por lo que me reservo de cualquier acción legal alguna.</p>)}
                />
              </div>
            }
            btn= 'Aceptar'
            action= {this.handleClickConfirmacion}
            close={this.handleClickCloseConfirmacion}
            open= {this.state.openDialogConfirmacion}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><Description /><span style={{paddingLeft:'15px'}}>Información del Vale de Retiro</span></InputAdornment></div>)}
            description= ''
            content= {
              <Grid container>
                  <ItemGrid xs={12} sm={12} md={3}>
                    <TextField
                        id="nu_ClaveSIICVale"
                        label="Clave SIIC"
                        error={this.state.required_nu_ClaveSIIC}
                        className={classes.textField}
                        value={this.state.nu_ClaveSIIC}
                        onChange={this.handleChange('nu_ClaveSIIC')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={3}>
                    <TextField
                        id="id_CentroEntrega"
                        select
                        label="TAD"
                        error={this.state.required_id_CentroEntrega}
                        className={classes.textField}
                        value={this.state.id_CentroEntrega}
                        onChange={this.handleChange('id_CentroEntrega')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                          value: this.state.id_CentroEntrega
                        }}
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                        disabled
                        margin="normal"
                        fullWidth
                      >
                        {this.state.centroEntregas.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} md={3}></ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                        id="nb_SuperIntendente"
                        label="Super Intendente"
                        error={this.state.required_nb_SuperIntendente}
                        className={classes.textField}
                        value={this.state.nb_SuperIntendente}
                        onChange={this.handleChange('nb_SuperIntendente')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                        id="nb_AsesorComercial"
                        label="Asesor Comercial"
                        error={this.state.required_nb_AsesorComercial}
                        className={classes.textField}
                        value={this.state.nb_AsesorComercial}
                        onChange={this.handleChange('nb_AsesorComercial')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                        id="nb_Operador"
                        label="Nombre Operador"
                        error={this.state.required_nb_Operador}
                        className={classes.textField}
                        value={this.state.nb_Operador}
                        onChange={this.handleChange('nb_Operador')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                        id="nb_RFCOperador"
                        label="RFC Operador"
                        error={this.state.required_nb_RFCOperador}
                        className={classes.textField}
                        value={this.state.nb_RFCOperador}
                        onChange={this.handleChange('nb_RFCOperador')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={3}>
                    <TextField
                        id="nb_NumeroEquipo"
                        label="Numero de Equipo"
                        error={this.state.required_nb_NumeroEquipo}
                        className={classes.textField}
                        value={this.state.nb_NumeroEquipo}
                        onChange={this.handleChange('nb_NumeroEquipo')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={3}>
                    <TextField
                        id="nu_CapacidadEquipo"
                        label="Capacidad del Equipo"
                        error={this.state.required_nu_CapacidadEquipo}
                        className={classes.textField}
                        value={this.state.nu_CapacidadEquipo}
                        onChange={this.handleChange('nu_CapacidadEquipo')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={3}>
                    <TextField
                        id="nb_NumeroCertificado"
                        label="Numero de Certificado"
                        error={this.state.required_nb_NumeroCertificado}
                        className={classes.textField}
                        value={this.state.nb_NumeroCertificado}
                        onChange={this.handleChange('nb_NumeroCertificado')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={3}>
                    <TextField
                        id="nb_NumeroPlacas"
                        label="Numero de Placas"
                        error={this.state.required_nb_NumeroPlacas}
                        className={classes.textField}
                        value={this.state.nb_NumeroPlacas}
                        onChange={this.handleChange('nb_NumeroPlacas')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                        id="nb_ProductoAnteriorVale"
                        label="Producto que Trasporte en el Viaje Anterior"
                        error={this.state.required_nb_ProductoAnteriorVale}
                        className={classes.textField}
                        value={this.state.nb_ProductoAnteriorVale}
                        onChange={this.handleChange('nb_ProductoAnteriorVale')}
                        margin="normal"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          style: {color: '#0d4377', 'fontWeight': 'bold'},
                          shrink: true,
                        }}
                        InputProps={{
                          style: {color: '#555555'}
                        }}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_Destino"
                      select
                      error={this.state.requiredDestino}
                      label="Destino"
                      className={classes.textField}
                      value={this.state.id_Destino}
                      onChange={this.handleChange('id_Destino')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.destinos.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                      id="id_FirmaVale"
                      select
                      error={this.state.requiredFirmaVale}
                      label="Autorizador para Firmar el Vale"
                      className={classes.textField}
                      value={this.state.id_FirmaVale}
                      onChange={this.handleChange('id_FirmaVale')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      fullWidth
                    >
                      {this.state.firmasVale.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ItemGrid>
                </Grid>
            }
            btn= 'Generar Vale'
            action= {this.handleClickVale}
            close={this.handleClickCloseVale}
            open= {this.state.openDialogVale}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><AssignmentReturned /><span style={{paddingLeft:'15px'}}>Descargar Factura</span></InputAdornment></div>)}
            description= {this.state.descriptionFactura}
            content= {
              <div>
                <iframe title="Factura" style={{minHeight: '63VH', minWidth: '1100px'}} src={this.state.urlFactura}></iframe>
              </div>
            }
            btn= 'Descargar'
            action= {this.handleClickFactura}
            close={this.handleClickCloseFactura}
            open= {this.state.openDialogFactura}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><FormatListBulleted /><span style={{paddingLeft:'15px'}}>Seguimiento de Pedidos</span></InputAdornment></div>)}
            content={this.state.seguimiento}
            close={this.handleClickCloseSeguimiento}
            open= {this.state.openDialogSeguimiento}
          />
          <FormDialog
            title={(<div><InputAdornment position="start"><Description /><span style={{paddingLeft:'15px'}}>Visor Acuse CRE</span></InputAdornment></div>)}
            content={this.state.htmlAcuseCre}
            btn= 'Imprimir'
            action={<Button style={{color: '#fff', background: '#ff9800', padding: '5px 10px'}} className={classes.button} onClick={this.generatePDFAcuse.bind(this)}><Print /> Imprimir</Button>}
            close={this.handleClickCloseAcuse}
            open= {this.state.openDialogAcuse}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.tr}
            onClose={() => this.setState({ tr: false })}
            ContentProps={{ 'className': classes.red }}
            message={this.state.message}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.tc}
            onClose={() => this.setState({ tc: false })}
            ContentProps={{ 'className': classes.green }}
            message={this.state.message}
          />
        </ItemGrid>
      </Grid>
    );
}
}

Pedidos.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Pedidos);
